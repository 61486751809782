import React from "react";
import { Modal, Button } from "react-bootstrap";
import { previewModules } from "../../components";

function TemplatePreviewModal(props) {
  const filteredModules = [];

  props.templateData.forEach((pres) => {
    previewModules.forEach((item) => {
      if (pres === item.id) {
        filteredModules.push(item);
      }
    });
  });

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="save-template"
      centered
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="save-template">{props.templateTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {filteredModules.map((component) => {
          return (
            <React.Fragment key={component.name}>
              <component.component name={component.name} />
            </React.Fragment>
          );
        })}
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default TemplatePreviewModal;
