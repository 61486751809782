import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import ImportIcon from "../components/assets/ImportIcon";

export const ScreenWrapper = ({
  screenTitle,
  importIcon,
  handleCsv,
  children,
}) => {
  const fileUploadInput = useRef(null);
  const handleClick = () => {
    fileUploadInput.current.click();
  };
  return (
    <>
      <div className="screen-header">
        <h1>{screenTitle}</h1>
        {importIcon && (
          <div className="button-import">
            <button className="btn-import" onClick={handleClick}>
              <ImportIcon height="16px" width="16px" />
              {`Import ${screenTitle}`}
            </button>
            <input
              type="file"
              className="import-input"
              multiple={false}
              name="csv-upload"
              accept=".csv"
              ref={fileUploadInput}
              onChange={handleCsv}
            />
          </div>
        )}
      </div>
      <Card className="component-card">{children}</Card>
    </>
  );
};
