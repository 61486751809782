import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

// Set Initial State
const initialState = {
  isLoading: true,
  isAuthenticated: false,
  authToken: null,
  mobileNavState: false,
  blankPresentationComponents: [],
  presentationData: [],
};

// create context
export const AppContext = createContext(initialState);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function setLoading(payload) {
    dispatch({
      type: "SET_LOADING",
      payload: payload,
    });
  }

  // Check if current session is authenticated
  function setAuthenticated(userToken) {
    // const userToken = localStorage.getItem("token");
    // console.log("31", userToken);
    if (userToken !== null) {
      dispatch({
        type: "IS_AUTHENTICATED",
        payload: true,
      });
    } else {
      dispatch({
        type: "IS_AUTHENTICATED",
        payload: false,
      });
    }
  }

  function setMobileNavState(payload) {
    dispatch({
      type: "SET_MOBILE_NAV_STATE",
      payload: payload,
    });
  }

  function updateBlankPresentationComponents(payload) {
    const arr = state.blankPresentationComponents;
    arr.push(payload);
    dispatch({
      type: "BLANK_PRESENTATION_COMPONENTS",
      payload: arr,
    });
  }

  return (
    <AppContext.Provider
      value={{
        isLoading: state.isLoading,
        isAuthenticated: state.isAuthenticated,
        authToken: state.authToken,
        mobileNavState: state.mobileNavState,
        blankPresentationComponents: state.blankPresentationComponents,
        setLoading,
        setAuthenticated,
        setMobileNavState,
        updateBlankPresentationComponents,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
