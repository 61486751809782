import React from "react";

function ClientNamePreviewModule() {
  return (
    <div className="p-3">
      <h4>Client Name</h4>
    </div>
  );
}

export default ClientNamePreviewModule;
