import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  getPresentations,
  trashPresentation,
} from "../../../../api/presentations";
import { PreviewModal } from "../../presentations/preview/PreviewModal";
import { PresentationListItem } from "./PresentationListItem";

export const PresentationList = ({
  setPresentationData,
  viewFilteredData,
  searchInput,
  setSearchInput,
}) => {
  let currentID = "";
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [showPresentations, setShowPresentations] = useState();

  // get presentations query
  const { data, isLoading, isError } = useQuery(
    "getPresentations",
    getPresentations
  );

  // send presentation to trash
  const { mutate } = useMutation(trashPresentation, {
    onSuccess: (data) => {
      queryClient.setQueryData(["getPresentations", { id: currentID }], data);
      queryClient.invalidateQueries("getPresentations");
    },
    onError: (response) => {
      console.log(response);
    },
  });

  // //delete presentation mutation
  // const { mutate } = useMutation(
  //   ["deletePresentation", currentID],
  //   () => deletePresentation(currentID),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("getPresentations");
  //     },
  //   }
  // );

  useEffect(() => {
    if (data) {
      setPresentationData(data);
      //   setShowPresentations(
      //     data.filter(
      //       (presentation) =>
      //         presentation.enabled === true && presentation.status === "published"
      //     )
      //   );
    }
  }, [data]);

  // list filtered presentations when view by filter is selected
  useEffect(() => {
    setShowPresentations(viewFilteredData);
    setSearchInput("");
  }, [viewFilteredData]);

  // // list all published presentations on load
  useEffect(() => {
    if (data) {
      setShowPresentations(
        data.filter(
          (presentation) =>
            presentation.enabled === true && presentation.status === "published"
        )
      );
    }
  }, []);

  // filter results on search input change
  useEffect(() => {
    if (viewFilteredData) {
      console.log("filtered Data");
      const fuse = new Fuse(viewFilteredData, {
        keys: ["presentationTitle", "slug"],
        threshold: 0.25,
      });

      const results = fuse.search(searchInput);
      setShowPresentations(
        searchInput ? results.map((result) => result.item) : viewFilteredData
      );
    }
  }, [searchInput]);

  // check to see if query is still loading. Return spinner during loading state
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    // check for error
    return <h4>There was an error loading case studies</h4>;
  } else {
    function handlePreview(data) {
      setShow(true);
      setTitle(data.presentationTitle);
      setId(data._id);
    }

    // when sending to trash, sending id to match and setting enabled to false. All other data should be the same.
    // regardless if item is
    function handleDeleteSelect(data) {
      currentID = data;
      mutate({
        id: currentID,
        status: "trash",
      });
    }
    console.log("show", showPresentations);
    return (
      <div className="presentation-list">
        {showPresentations &&
          showPresentations.map((presentation, idx) => (
            <PresentationListItem
              presentation={presentation}
              key={`presentation-list-${idx}`}
              handleDeleteSelect={handleDeleteSelect}
              handlePreview={handlePreview}
            />
          ))}
        <PreviewModal show={show} onHide={setShow} title={title} id={id} />
      </div>
    );
  }
};
