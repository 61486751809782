import React, { useState, useRef } from "react";

export const MediaUpload = (props) => {
  const fileUploadInput = useRef(null);
  let fileArr;

  const handleClick = () => {
    fileUploadInput.current.click();
  };

  function handleMediaUpload(e) {
    fileArr = Array.from(e.target.files);
    props.uploadedFiles(fileArr);
  }

  return (
    <div className="rw_media_upload">
      <p className="rw_media_upload_text">Drag and Drop files here</p>
      <p className="rw_media_upload_text">or</p>
      <button className="rw_media_upload_button" onClick={handleClick}>
        Browse Files
      </button>
      <input
        type="file"
        className="rw_media_upload_input"
        multiple={props.multiple}
        name="media"
        accept=".jpg,.jpeg,.png,.pdf,.mp4,.mov"
        ref={fileUploadInput}
        onChange={handleMediaUpload}
      />
      {/* <MediaUploadButton multiple={props.multiple} uploadedFiles={setFileList} /> */}
    </div>
  );
};
