import { CaseStudies } from "./CaseStudies";
import { ContactInformation } from "./ContactInformation";
import { Documents } from "./Documents";
import { Images } from "./Images";
import { MeetTheTeam } from "./MeetTheTeam";
import { Services } from "./Services";
import { Testimonials } from "./Testimonials";
import { Text } from "./Text";
import { Videos } from "./Videos";

export const frontendModules = [
  {
    id: "3",
    name: "Meet The Team",
    component: MeetTheTeam,
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformation,
  },
  {
    id: "5",
    name: "Documents",
    component: Documents,
  },
  {
    id: "6",
    name: "Case Studies",
    component: CaseStudies,
  },
  {
    id: "7",
    name: "Video",
    component: Videos,
  },
  {
    id: "8",
    name: "Testimonials",
    component: Testimonials,
  },
  {
    id: "9",
    name: "Services",
    component: Services,
  },
  {
    id: "10",
    name: "Images",
    component: Images,
  },
  {
    id: "11",
    name: "Text",
    component: Text,
  },
];
