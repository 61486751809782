import Fuse from "fuse.js";
import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { getServices } from "../../../../../../api/services";

export const ServiceSelect = ({ setSelected, selectId, selected }) => {
  const { data, isLoading, isError } = useQuery("getServices", getServices);

  const inputEl = useRef(null);
  const [searched, setSearched] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  function handleSelect(selectedItem) {
    // set selected items to an array
    setSelected((prev) => [...prev, selectedItem]);
    inputEl.current.blur();
    setShowDropdown(false);
  }

  function handleBlur(e) {
    setSearched("");
    setShowDropdown(false);
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading services</h4>;
  } else {
    const fuse = new Fuse(data, {
      keys: ["title"],
      threshold: 0.25,
    });

    function handleSearch({ currentTarget = {} }) {
      const { value } = currentTarget;
      setSearched(value);
    }

    const results = fuse.search(searched);
    const match = searched ? results.map((result) => result.item) : data;

    return (
      <div className="editor-select">
        <Form.Control
          type="text"
          ref={inputEl}
          value={searched}
          placeholder="Search Services"
          onChange={handleSearch}
          onFocus={() => setShowDropdown(true)}
          onBlur={handleBlur}
        />
        <Dropdown.Menu
          className="editor-select-dropdown"
          show={showDropdown}
          onMouseDown={(e) => e.preventDefault()}
          flip={false}
          rootCloseEvent="click"
        >
          {match.map((opt, idx) => (
            <Dropdown.Item
              className="editor-select-dropdown-item"
              key={`${selectId}-${idx}`}
              onClick={() => handleSelect(opt)}
              disabled={selected.some(
                (item) =>
                  String(item._id + selectId) === String(opt._id + selectId)
              )}
            >
              <div className="editor-select-dropdown-item-name">
                <p>{opt.title}</p>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </div>
    );
  }
};
