import React from "react";
import { Container, Navbar } from "react-bootstrap";

export const FrontEndHeader = () => {
  function handleContact(e) {
    e.preventDefault();
    alert("link to a contact page? phone?");
  }
  return (
    <header className="presentation-header">
      <Navbar bg="dark" variant="dark">
        <Container fluid className="d-flex flex-column flex-sm-row">
          <Navbar.Brand className="text-white">Company Logo</Navbar.Brand>
          <Navbar.Text className="text-center">
            Page expires in 4 days{" "}
            <a className="btn btn-primary" href="#" onClick={handleContact}>
              GET IN TOUCH
            </a>
          </Navbar.Text>
        </Container>
      </Navbar>
    </header>
  );
};
