import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ExpirationDatePicker from "./ExpirationDatePicker";
import ExpirationDateTimePicker from "./ExpirationDateTimePicker";

export const ExpirationSelect = ({
  status,
  setStatus,
  publishDate,
  savedExpirationDate,
  setNewExpirationDate,
}) => {
  const [currentDate, setCurrentDate] = useState();
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    setCurrentTime(moment(savedExpirationDate));
    setCurrentDate(moment(savedExpirationDate));
  }, [savedExpirationDate]);

  useEffect(() => {
    const time = moment(currentTime).format("LT");
    const date = moment(currentDate).format("L");
    const dateTime = moment(date + " " + time, "MM/DD/YYYY h:mm a");
    setNewExpirationDate(moment(dateTime).format());

    // if (dateTime.format() >= moment().format()) {
    //   setStatus("scheduled");
    // }
  }, [currentDate, currentTime]);

  return (
    <div className="d-flex flex-column flex-lg-row ">
      <Form.Check
        className="d-flex align-items-center"
        name="expirationStatus"
        type="radio"
        value="expires"
        onChange={() => setStatus("expires")}
        checked={status === "expires"}
        label={
          <div className="d-flex align-items-center ms-3">
            {status !== "expires" ? (
              <p className="mb-0">Set Expiration</p>
            ) : (
              <p className="mb-0">Expries On</p>
            )}
          </div>
        }
      />
      <div
        className={`d-flex align-items-md-center flex-column flex-md-row ${
          status === "never" && "d-none"
        }`}
      >
        <p className="mx-3 mb-0">Date:</p>
        <ExpirationDatePicker
          publishDate={publishDate}
          setCurrentDate={setCurrentDate}
          setCurrentTime={setCurrentTime}
          currentDate={currentDate}
        />

        <p className="mx-3 mb-0">Time:</p>
        <ExpirationDateTimePicker
          setCurrentTime={setCurrentTime}
          currentTime={currentTime}
          // currentDate={currentDate}
          // expirationDate={expirationDate}
          publishDate={publishDate}
        />
      </div>
    </div>
  );
};
