import React from "react";

function ContactInformationPreviewModule() {
  return (
    <div className="p-3">
      <ul>
        <li>Team Contact Info</li>
        <li>Team Contact Info</li>
        <li>Team Contact Info</li>
        <li>Team Contact Info</li>
      </ul>
    </div>
  );
}

export default ContactInformationPreviewModule;
