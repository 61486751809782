import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      Page Not Found
      <Link className="btn btn-primary" to="/">
        Return to Dashboard
      </Link>
    </div>
  );
}

export default NotFound;
