import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function MeetTheTeamPreviewModule() {
  return (
    <Container>
      <Row>
        <Col md={4}>
          <Card className="bg-dark">
            <h4 className="text-white">Team Image</h4>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mx-2 bg-dark">
            <h4 className="text-white">Team Image</h4>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mx-2 bg-dark">
            <h4 className="text-white">Team Image</h4>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default MeetTheTeamPreviewModule;
