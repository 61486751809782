import api from "./api";

export const createNewPresentation = async (payload) => {
  const { data } = await api.post("/presentation/create", payload);
  return data;
};

export const getPresentations = async () => {
  const { data } = await api.get("/presentation");
  return data;
};

export const getPresentation = async (id) => {
  const { data } = await api.get(`/presentation/${id}`);
  return data;
};

export const deletePresentation = async (id) => {
  const data = await api.delete(`/presentation/delete/${id}`);
  return data;
};

export const editPresentation = async (payload) => {
  const data = await api.put("/presentation/edit", payload);
  return data;
};

export const trashPresentation = async (payload) => {
  const data = await api.put("/presentation/trash", payload);
  return data;
};

// Public Presentations
export const getPublicPresentation = async (slug) => {
  const { data } = await api.get(`/presentations/${slug}`);
  return data;
};
