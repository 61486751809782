import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "react-query";
import { deleteMedia, editMedia } from "../../../api/media";
import { imageDimensions } from "../constants/imageDimensions";
import { imageSize } from "../constants/imageSize";

export const FileInfo = ({
  files,
  currentChecked,
  insertable,
  selectedImage,
  reset,
  allSelectedItems,
}) => {
  console.log(insertable);
  const queryClient = useQueryClient();
  const [fileDimensions, setFileDimensions] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [fileDate, setFileDate] = useState(null);
  const [fileAlt, setFileAlt] = useState("");
  const [isImage, setIsImage] = useState(false);
  const { user } = useAuth0();

  let fileInfo = [];
  const allItems = [];

  // set fileInfo to current checked
  files.forEach((file) => {
    if (file._id === currentChecked) {
      fileInfo = file;
    }
  });

  // match all selected items to the file and save those matched files to array
  allSelectedItems.forEach((item) => {
    files.forEach((file) => {
      if (item === file._id) {
        allItems.push(file);
      }
    });
  });

  useEffect(() => {
    if (fileInfo.fileType !== "pdf" && fileInfo.fileType !== "mp4") {
      imageDimensions(fileInfo.fileUrl[0])
        .then(({ width, height }) => {
          setFileDimensions(width + "px x " + height + "px");
        })
        .catch((errorMessage) => console.log(errorMessage));
      setIsImage(true);
      setFileAlt(fileInfo.fileAlt);
    } else {
      setFileDimensions(null);
      setIsImage(false);
    }
    setFileSize(imageSize(fileInfo.fileSize));
    setFileDate(moment(fileInfo.updatedAt).format("MMMM Do YYYY, h:mm:ss a"));
  }, [fileInfo, files]);

  function handleSelectImage() {
    selectedImage(allItems);
  }

  function updateFileInfo(e) {
    setFileAlt(e.target.value);
  }

  // Delete Mutation
  const { mutate: deleteMutation } = useMutation(
    ["deleteMedia", fileInfo._id],
    () => deleteMedia(fileInfo._id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allMedia");
        reset("");
      },
    }
  );

  // Edit Mutation
  const { mutate: editMutation } = useMutation(editMedia, {
    onSuccess: () => {
      queryClient.invalidateQueries("allMedia");
    },
  });

  function handleUpdate() {
    editMutation({
      user: user.sub,
      id: fileInfo._id,
      alt: fileAlt,
    });
  }

  function handleDelete() {
    deleteMutation();
  }
  return (
    <div className="rw_media_library_file_info">
      <p className="rw_media_library_file_info_title">Attachment Details</p>
      <p className="rw_media_library_file_info_fileName">{fileInfo.fileName}</p>
      <div className="rw_media_library_file_info_media_info">
        <img
          src={fileInfo.image}
          width={63}
          height={63}
          alt={fileInfo.fileName}
        />
        <div>
          <p className="rw_media_library_file_info_fileData">
            {fileDimensions}
          </p>
          <p className="rw_media_library_file_info_fileData">{fileSize}</p>
          <p className="rw_media_library_file_info_fileData">{fileDate}</p>
        </div>
      </div>
      {/* <div className="rw_media_library_file_info_input_group">
        <label htmlFor="file_name">File Name</label>
        <input id="file_name" type="text" />
      </div> */}
      {isImage && (
        <div className="rw_media_library_file_info_input_group">
          <label htmlFor="alt_text">Alt Text</label>
          <input
            id="alt_text"
            type="text"
            value={fileAlt}
            onChange={updateFileInfo}
          />
        </div>
      )}
      <div className="rw_media_library_file_update">
        {isImage && (
          <button
            className="rw_media_library_update_btn"
            onClick={handleUpdate}
          >
            Update
          </button>
        )}
        <button className="rw_media_library_delete_btn" onClick={handleDelete}>
          Delete
        </button>
      </div>
      {insertable ? (
        <div className="rw_media_library_file_insert">
          <button
            className="rw_media_library_file_insert_button"
            onClick={handleSelectImage}
          >
            Select
          </button>
        </div>
      ) : null}
    </div>
  );
};
