import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { slugify } from "../../../components/slugify";
import {
  getPublicPresentation,
  getPresentation,
} from "../../../../api/presentations";
import { FrontEndLayout } from "../frontend/layouts/FrontEndLayout";
import { ClientInfo } from "../frontend/modules/ClientInfo";
import { frontendModules } from "../frontend/modules/components";

export const PresentationPreview = ({ id }) => {
  const { data, isLoading, isError } = useQuery(["getPresentation", id], () =>
    getPresentation(id)
  );

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this presentation</h4>;
  } else {
    const presentationData = data[0];
    const presentationComponents = frontendModules.filter((data) =>
      presentationData.layout.includes(data.id)
    );
    // const presentationComponents =
    console.log("39", presentationData);
    return (
      <div className="presentation-page py-5">
        {presentationData.data.client.active && (
          <ClientInfo data={presentationData.data.client} />
        )}
        <Row>
          <Col md={3}>
            <div className="presentation-side-navigation">
              <p className="navigation-title">Jump to:</p>
              {presentationComponents.map((component, idx) => (
                <div key={`presentation-nav-${idx}`}>
                  <a href={`#${slugify(component.name)}`}>{component.name}</a>
                </div>
              ))}
            </div>
          </Col>
          <Col>
            <div className="presentation-display">
              {presentationComponents.map((component, idx) => (
                <div
                  key={`presentation-${idx}`}
                  id={slugify(component.name)}
                  className="presentation-item-container"
                >
                  <component.component
                    data={presentationData.data[component.id]}
                    title={component.name}
                    id={slugify(component.name)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};
