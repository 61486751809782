import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ShowImage } from "./ShowImage";
import { createNewMedia, getMedia } from "../../../api/media";
import Library from "../../../media-library/Library";
import { ShowVideo } from "./ShowVideo";

export const AddVideoComponent = ({
  videoSelect,
  selectedVideo,
  multiple,
  showVideo,
}) => {
  const queryClient = useQueryClient();

  const [modalShow, setModalShow] = useState(false);
  const [mediaFilesToUpload, setMediaFilesToUpload] = useState([]);
  const [loadError, setLoadError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setModalShow(false);

  // Initial Upload Mutation
  const { mutate: initialUpload } = useMutation(createNewMedia, {
    onSuccess: (newMedia) => {
      const data = newMedia.data.uploadResponse;
      data.forEach((file) => {
        queryClient.setQueryData("allMedia", (prev) => [...prev, file]);
      });

      setMediaFilesToUpload([]);
    },
  });

  // Get Media Query
  const { data, isLoading, isError } = useQuery("allMedia", getMedia, {
    onError: () => {
      setLoadError(true);
    },
    onSuccess: () => {
      setSuccess(true);
    },
  });

  // set form data and mutate
  useEffect(() => {
    const formData = new FormData();
    if (mediaFilesToUpload && mediaFilesToUpload.length) {
      mediaFilesToUpload.forEach((file) => {
        formData.append("file", file);
        formData.append("name", file.name);
      });
      initialUpload(formData);
    }
  }, [mediaFilesToUpload]);

  useEffect(() => {
    handleClose();
  }, [selectedVideo]);
  // handle errors
  if (isError || loadError || !success) {
    return <>error</>;
  }

  function showModal() {
    setModalShow(true);
  }

  // return if successful
  if (success) {
    return (
      <div className="add-media-button">
        <div className="add-media-button-container">
          <div>
            <Button variant="outline-primary" size="lg" onClick={showModal}>
              Upload / Select Video
            </Button>
          </div>
          {showVideo && (
            <ShowVideo
              selectedVideo={selectedVideo}
              showModal={showModal}
              videoSelect={videoSelect}
            />
          )}
        </div>
        <Modal
          show={modalShow}
          size="xl"
          backdrop="static"
          aria-labelledby="add-modules"
          centered
          onHide={handleClose}
        >
          <Library
            selectedImage={videoSelect}
            insertable={true}
            initialFiles={data}
            tabViewsTitles={["Videos"]}
            uploadMultiple={true}
            onUpload={setMediaFilesToUpload}
            selectMultiple={multiple}
            isLoading={isLoading}
          />
          <Modal.Footer>
            <button onClick={handleClose}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};
