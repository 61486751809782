import React from "react";

export const CollectionList = (props) => {
  return (
    <div className="collection-list">
      <h2 className="collection-list-title">{props.title}</h2>
      {props.children}
    </div>
  );
};
