import api from "./api";

export const createNewTeamMember = async (payload) => {
  const data = await api.post("/team-members/create", payload);
  return data;
};

export const getTeamMembers = async () => {
  const { data } = await api.get("/team-members");
  return data;
};

export const getTeamMember = async (id) => {
  const { data } = await api.get(`/team-members/${id}`);
  return data;
};

export const deleteTeamMember = async (id) => {
  const data = await api.delete(`/team-members/delete/${id}`);
  return data;
};

export const editTeamMember = async (payload) => {
  const data = await api.put("/team-members/edit", payload);
  return data;
};
