import React from "react";
import { Form } from "react-bootstrap";
import { SearchFilter } from "./SearchFilter";
import { SortByFilter } from "./SortByFilter";
import { ViewByFilter } from "./ViewByFilter";

export const PresentationFilter = ({
  presentationData,
  setViewFilteredData,
  setSearchInput,
  searchInput,
}) => {
  return (
    <div className="dashboard-filter-wrapper">
      <div className="dashboard-search">
        <SearchFilter
          setSearchInput={setSearchInput}
          searchInput={searchInput}
        />
      </div>
      <div className="dashboard-view-by">
        <ViewByFilter
          presentationData={presentationData}
          setViewFilteredData={setViewFilteredData}
        />
      </div>
      {/* <div className="dashboard-sort-by">
        <SortByFilter />
      </div> */}
      <div className="dashboard-filter"></div>
    </div>
  );
};
