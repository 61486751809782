import React from "react";
import dompurify from "dompurify";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";

export const ContactInformation = ({ id, data, title }) => {
  // const active = true;
  // const teamMembers = data.teamMembers;
  const sanitizer = dompurify.sanitize;
  console.log("contact", data);
  if (!data || !data.active) {
    return null;
  } else {
    return (
      <ComponentWrapper id={id} title={title}>
        <Row>
          {data.teamMembers.map((teamMember, idx) => (
            <Col key={`tm-${idx}`} md={4}>
              <figure>
                <img
                  src={
                    teamMember.image
                      ? teamMember.image[0].fileUrl[3]
                      : placeholder
                  }
                  width="100%"
                  height="auto"
                />
              </figure>
              <h3>{teamMember.name}</h3>
              <h4>{teamMember.title}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(teamMember.bio),
                }}
              ></div>
            </Col>
          ))}
        </Row>
      </ComponentWrapper>
    );
  }
};
