import React from "react";
import { Form } from "react-bootstrap";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import TrashIcon from "../../../../components/assets/TrashIcon";

export const EditBar = ({
  draggable,
  active,
  setActive,
  dragHandle,
  componentId,
  setFilteredModules,
  filteredModules,
}) => {
  function handleRemoveModule() {
    setFilteredModules(
      filteredModules.filter((item) => item.id !== componentId)
    );
    // setSelected(selected.filter((item) => item._id !== id));
  }
  return (
    <div className="edit-bar">
      <Form.Check
        type="switch"
        id="custom-switch"
        checked={active}
        onChange={() => setActive(!active)}
      />
      {setFilteredModules && (
        <div className="delete-icon">
          <button className="transparent" onClick={handleRemoveModule}>
            <TrashIcon height={24} width={24} />
          </button>
        </div>
      )}
      {draggable && (
        <div className="reorder-icon" {...dragHandle}>
          <ReorderIcon height={24} width={24} />
        </div>
      )}
    </div>
  );
};
