import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import { AddMediaComponent } from "../../../../components/media/AddMediaComponent";
import { EditBar } from "./EditBar";

export const ClientInfoModule = ({ setData, data, initialData }) => {
  const [active, setActive] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [note, setNote] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");

  useEffect(() => {
    setData({
      ...data,
      client: {
        active: active,
        clientName: clientName,
        clientImage: selectedImage,
        clientNote: note,
        clientEmail: clientEmail,
      },
    });
  }, [active, clientName, selectedImage, note]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      //   setData({
      //     ...data,
      //     client: {
      //       active: initialData.active,
      //       clientName: initialData.clientImage,
      //       clientImage: initialData.clientName,
      //       clientNote: initialData.clientNote,
      //     },
      //   });
      setActive(initialData.active);
      setSelectedImage(initialData.clientImage);
      setClientName(initialData.clientName);
      setNote(initialData.clientNote);
      setClientEmail(initialData.clientEmail);
    }
  }, [initialData]);
  return (
    <Card className="component-card client-name-editor-module editor-module">
      {/* {draggable ?
        <div className="draggable-icon">Icon</div>
        : null
      } */}
      <EditBar
        draggable={false}
        active={active}
        setActive={setActive}
        deletable={false}
      />
      <h2 className="mb-3">Client Information</h2>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Name</p>
        <input
          type="text"
          className="form-control"
          aria-label="Client Name"
          placeholder="Client Name"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
          name="clientName"
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Email</p>
        <input
          type="email"
          className="form-control"
          aria-label="Client Email"
          placeholder="Client Email"
          value={clientEmail}
          onChange={(e) => setClientEmail(e.target.value)}
          name="clientEmail"
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Client Logo</p>
        <AddMediaComponent
          imageSelect={setSelectedImage}
          selectedImage={selectedImage}
          showImages={true}
        />
      </div>
      <div className="mb-3">
        <p className="editor-module-field-label">Note To Client</p>
        <ReactQuill theme="snow" value={note} onChange={setNote} />
      </div>
    </Card>
  );
};
