import React from "react";
import Footer from "../partials/global/Footer";
import Header from "../partials/global/Header";
import Sidepanel from "../partials/global/Sidepanel";
function Layout(props) {
  return (
    <>
      <div id="skip" className="visually-hidden">
        <a href="#main-container">Skip to content</a>
      </div>
      <Header />
      <div id="page-content-wrapper" className="d-flex flex-column flex-md-row">
        <Sidepanel />
        <main id="main-container" className="w-100">
          <div className="main-wrapper">{props.children}</div>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
