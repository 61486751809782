import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import { getService, editService } from "../../../api/services";
// import DepartmentSelect from "../../components/DepartmentSelect";
// import IndustrySelect from "../../components/IndustrySelect";
import { AddMediaComponent } from "../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../layouts/CollectionCreate";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import { Helmet } from "../../layouts/Helmet";

export const EditService = () => {
  // const [department, setDepartment] = useState([])
  // const [industry, setIndustry] = useState([])
  // const [currentDepartment, setCurrentDepartment] = useState([])
  // const [currentIndustry, setCurrentIndustry] = useState([])
  const [description, setDescription] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [inputValue, setInputValue] = useState({
    title: "",
    url: "",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/");
  const serviceID = path[path.length - 1];
  const { user } = useAuth0();

  // get services query
  const { data } = useQuery(["getService", serviceID], () =>
    getService(serviceID)
  );

  const { mutate, isLoading, isError } = useMutation(editService, {
    onSuccess: (data) => {
      queryClient.setQueryData(["getServices", { id: serviceID }], data);
    },
  });

  // set values on load
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setOriginalName(data[0].title);
      setInputValue({
        title: data[0].title,
        url: data[0].url,
      });
      if (data[0].description) {
        setDescription(data[0].description);
      }
    }
  }, [data]);

  // update inputs
  function handleInput(e) {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // console.log(department)
    // let newDeptArr = []
    // if (department) {
    //   department.map(dept => {
    //     newDeptArr.push(dept.value)
    //   })
    // }
    // console.log(newDeptArr)
    // let newIndArr = []
    // if (industry) {
    //   industry.map(ind => {
    //     newIndArr.push(ind.value)
    //   })
    // }

    mutate({
      id: serviceID,
      title: inputValue.title,
      url: inputValue.url,
      // department: newDeptArr,
      // industry: newIndArr,
      description: description,
      user: user.sub,
    });
    navigate("/services");
  }
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this service</h4>;
  } else {
    return (
      <ScreenWrapper importIcon={false} screenTitle={`Edit ${originalName}`}>
        <Helmet metaTitle="Edit Service | Spark | RebelWare" />
        <CollectionCreate>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Title"
                  placeholder="Enter Title"
                  value={inputValue.title}
                  onChange={handleInput}
                  name="title"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="url"
                  className="form-control"
                  aria-label="Url"
                  placeholder="Enter Link Address"
                  value={inputValue.url}
                  onChange={handleInput}
                  name="url"
                />
              </Col>
            </Row>
            {/* <Row>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <DepartmentSelect currentState={currentDepartment} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <IndustrySelect currentState={currentIndustry} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
              </div>
            </Col>
          </Row> */}
            <Row>
              <Col md={12} className="mb-3">
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-between">
              <Button
                as={Link}
                to="/services"
                variant="secondary"
                size="lg"
                type="button"
              >
                Cancel
              </Button>
              <Button variant="primary" size="lg" type="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </CollectionCreate>
      </ScreenWrapper>
    );
  }
};
