import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../views/screens/login/Login";
import { Presentations } from "../views/screens/presentations/frontend/Presentations";

function PublicRouter() {
  return (
    <Routes>
      <Route exact path="presentations/:slug" element={<Presentations />} />
      <Route exact path="drafts/:_id" element={<Presentations />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default PublicRouter;
