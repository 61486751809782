import React, { useEffect, useState } from "react";
import moment from "moment";
import { AllMedia } from "../../../../media-library/components/media-folders/AllMedia";

export const ViewByFilter = ({ presentationData, setViewFilteredData }) => {
  const date = moment(new Date()).format();
  const [active, setActive] = useState("filter-0");
  const [allArr, setAllArr] = useState([]);
  const [activeArr, setActiveArr] = useState([]);
  const [draftArr, setDraftArr] = useState([]);
  const [scheduledArr, setScheduledArr] = useState([]);
  const [expiredArr, setExpiredArr] = useState([]);
  const [trashArr, setTrashArr] = useState([]);
  // if there are presentations, get the counts for filters.
  useEffect(() => {
    if (presentationData) {
      presentationData.forEach((e) => console.log("17", e.status));
      // get trash presentations
      const trash = presentationData.filter((data) => data.status === "trash");
      setTrashArr(trash);

      // get all presentations
      const all = presentationData.filter((data) => data.status !== "trash");
      setAllArr(all);
      setViewFilteredData(all);
      // get draft presentations
      const draft = presentationData.filter((data) => data.status === "draft");
      setDraftArr(draft);

      // get scheduled presentations
      const scheduled = presentationData.filter(
        (data) => data.publishDate > date && data.status !== "trash"
      );
      setScheduledArr(scheduled);

      // get expired presentations
      const expired = presentationData.filter(
        (data) => data.expirationDate < date && data.status !== "trash"
      );
      setExpiredArr(expired);

      // get active presentations

      setActiveArr(
        presentationData.filter(
          (data) =>
            !expired.includes(data) &&
            !scheduled.includes(data) &&
            !draft.includes(data) &&
            !trash.includes(data)
        )
      );
    }
  }, [presentationData]);

  function handleClick(e) {
    setActive(e.target.id);
    // eslint-disable-next-line eqeqeq
    const arr = filters.filter((item, idx) => e.target.dataset.key == idx);
    setViewFilteredData(arr[0].array);
  }

  const filters = [
    {
      name: "All",
      array: allArr,
    },
    {
      name: "Active",
      array: activeArr,
    },
    {
      name: "Scheduled",
      array: scheduledArr,
    },
    {
      name: "Expired",
      array: expiredArr,
    },
    {
      name: "Drafts",
      array: draftArr,
    },
    {
      name: "Trash",
      array: trashArr,
    },
  ];

  return (
    <>
      <h2 className="mb-3">Filter</h2>
      <div className="filter-by-selectors">
        <p className="filter-title">View By:</p>
        {filters.map((item, idx) => (
          <button
            className={`filters transparent ${
              active === `filter-${idx}` && "active"
            }`}
            onClick={handleClick}
            id={`filter-${idx}`}
            key={`filter-${idx}`}
            data-key={idx}
          >
            {`${item.name} (${item.array.length})`}
          </button>
        ))}
      </div>
    </>
  );
};
