import React from "react";

export const ComponentWrapper = ({ children, title, id }) => {
  return (
    <div className={id}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};
