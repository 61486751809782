import React from "react";
import { CreateTeamMember } from "./modules/CreateTeamMember";
import { ListTeamMembers } from "./modules/ListTeamMembers";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";

export const TeamMembers = () => {
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {},
      });
    }
  }
  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Team Members"
    >
      <Helmet metaTitle="Team Members | Spark | RebelWare" />
      <CreateTeamMember />
      <ListTeamMembers />
    </ScreenWrapper>
  );
};
