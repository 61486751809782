import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeIcon from "../../../assets/images/icons/home_icon.svg";

function MainNavigation() {
  const [active, setActive] = useState("");
  const url = window.location.pathname;
  const currentUrl = url.split("/")[1];
  useEffect(() => {
    document.querySelectorAll("#main-navigation .nav-link").forEach((el) => {
      el.classList.remove("active");
      const currentelhref = el.href.split("/");
      if (currentUrl === currentelhref[currentelhref.length - 1]) {
        setActive(currentUrl);
      }
    });
  }, [window.location]);

  function handleCreate() {
    document.querySelectorAll("#main-navigation .nav-link").forEach((el) => {
      el.classList.remove("active");
    });
  }

  return (
    <Nav
      id="main-navigation"
      activeKey={active}
      onSelect={(selectedKey) => setActive(selectedKey)}
    >
      <Link
        onClick={handleCreate}
        to="/presentation-template"
        className="btn btn-primary"
      >
        Create Presentation
      </Link>
      <Nav.Link as={Link} eventKey="dashboard" to="/">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Dashboard</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/team-members" eventKey="team-members">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Team Members</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/testimonials" eventKey="testimonials">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Testimonials</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/case-studies" eventKey="case-studies">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Case Studies</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/services" eventKey="services">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Services</div>
      </Nav.Link>
      <Nav.Link as={Link} to="/media" eventKey="media">
        <img src={HomeIcon} alt="home icon" width="16" height="16" />
        <div className="span">Media Library</div>
      </Nav.Link>
    </Nav>
  );
}

export default MainNavigation;
