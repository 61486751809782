import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { createNewTeamMember } from "../../../../api/teamMembers";
// import DepartmentSelect from "../../../components/DepartmentSelect";
// import IndustrySelect from "../../../components/IndustrySelect";
import { AddMediaComponent } from "../../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../../layouts/CollectionCreate";

export const CreateTeamMember = () => {
  const { user } = useAuth0();
  const [text, setText] = useState("");
  // const [clear, setClear] = useState("")
  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  // const [department, setDepartment] = useState([]);
  // const [selectedDepartment, setSelectedDepartment] = useState([]);
  // const [industry, setIndustry] = useState([]);
  // const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [input, setInput] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
  });

  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation(createNewTeamMember, {
    onSuccess: (newTeamMember) => {
      const data = newTeamMember.data.TeamMember;
      queryClient.setQueryData("getTeamMembers", (prev) => [...prev, data]);
      setImage("");
      setText("");
      // setClear("clear")
      // setSelectedDepartment([])
      // setDepartment([])
      // setSelectedIndustry([])
      // setIndustry([])
      setInput({
        title: "",
        name: "",
        email: "",
        phone: "",
      });
    },
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   let dept = [];
  //   if (department) {
  //     department.map(p => {
  //       dept.push(p.value)
  //     })
  //   }
  //   setSelectedDepartment(dept)
  //
  // }, [department])

  // useEffect(() => {
  //   let ind = [];
  //   if (industry) {
  //     industry.map(p => {
  //       ind.push(p.value)
  //     })
  //   }
  //   setSelectedIndustry(ind)
  //
  // }, [industry])

  function handleSubmit(e) {
    e.preventDefault();
    if (input.name === "") {
      alert("No Team Member Name");
    } else {
      mutate({
        name: input.name,
        title: input.title,
        email: input.email,
        phone: input.phone,
        image: selectedImage,
        bio: text,
        user: user.sub,
        // department: selectedDepartment,
        // industry: selectedIndustry,
      });
    }
  }

  if (isError) {
    return <div>There has been an error</div>;
  }

  return (
    <CollectionCreate title="Create New Team Member">
      <AddMediaComponent
        imageSelect={setSelectedImage}
        selectedImage={selectedImage}
        message="Recommended Image size is 350px x 525px"
        showImages={true}
      />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Name"
              placeholder="Enter First and Last Name"
              value={input.name}
              onChange={handleInput}
              name="name"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Title"
              value={input.title}
              onChange={handleInput}
              name="title"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="email"
              className="form-control"
              aria-label="email"
              placeholder="Enter Email"
              value={input.email}
              onChange={handleInput}
              name="email"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="tel"
              className="form-control"
              aria-label="phone"
              placeholder="Enter Phone Number (XXX-XXX-XXXX)"
              value={input.phone}
              onChange={handleInput}
              name="phone"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <DepartmentSelect clear={clear} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <IndustrySelect clear={clear} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="mb-3">
            <ReactQuill
              theme="snow"
              value={text}
              onChange={setText}
              placeholder={"Add Team Member Bio"}
            />
          </Col>
        </Row>
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Team Member
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
};
