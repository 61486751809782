import dompurify from "dompurify";
// import Loader from "react-loader-spinner";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getServices, deleteService } from "../../../../api/services";
import PencilIcon from "../../../components/assets/PencilIcon";
import TrashIcon from "../../../components/assets/TrashIcon";
import { CollectionList } from "../../../layouts/CollectionList";

export const ListServices = () => {
  let currentID = "";
  const sanitizer = dompurify.sanitize;
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery("getServices", getServices);

  function handleDeleteSelect(data) {
    currentID = data;
    mutate();
  }

  const { mutate } = useMutation(
    ["deleteService", currentID],
    () => deleteService(currentID),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getServices");
      },
    }
  );

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading services</h4>;
  } else {
    return (
      <CollectionList
        title="View / Edit Services"
        loading={isLoading}
        error={isError}
      >
        {data.map((service) => (
          <div className="collection-list-card" key={service._id}>
            <div className="collection-list-col collection-list-info">
              <div className="mb-sm-4">
                <p>{service.title}</p>
              </div>
              <div>
                <p>{service.url}</p>
              </div>
            </div>
            <div className="collection-list-col collection-list-textarea">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(service.description),
                }}
              ></div>
              {/* <div className="d-flex">
            <div className="me-4 d-flex">
              <p className="me-2 fw-bold">Departments:</p>
              {service.department.map((item, i) => (
                <div className="me-2" key={`dept_${i}`}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className="d-flex">
              <p className="me-2 fw-bold">Industries:</p>
              {service.industry.map((item, i) => (
                <div className="me-2" key={`ind_${i}`}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div> */}
            </div>
            <div className="collection-list-buttons">
              <Link to={{ pathname: `/services/edit/${service._id}` }}>
                <PencilIcon width="1rem" height="1rem" />
              </Link>
              <button
                className="transparent"
                onClick={() => handleDeleteSelect(service._id)}
              >
                <TrashIcon width="1rem" height="1rem" />
              </button>
            </div>
          </div>
        ))}
      </CollectionList>
    );
  }
};
