import React from "react";
import "./assets/scss/app.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppProvider } from "./context/AppContext";
import AuthController from "./views/layouts/authentication/AuthControler";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <AuthController />
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}
