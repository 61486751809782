import React from "react";
import PencilIcon from "../assets/PencilIcon";
import TrashIcon from "../assets/TrashIcon";

export const ShowImage = ({ selectedImage, showModal, imageSelect }) => {
  if (!selectedImage) {
    return <p className="show-image-text my-3">No Image Selected</p>;
  } else {
    return (
      <div className="show-image-container my-3">
        {selectedImage.map((image) => (
          <React.Fragment key={image._id}>
            <div className="show-image-buttons">
              <button className="transparent" onClick={showModal}>
                <PencilIcon withd={14} height={14} />
              </button>
              <button className="transparent" onClick={() => imageSelect(null)}>
                <TrashIcon withd={14} height={14} />
              </button>
            </div>
            <img
              src={image.fileUrl[3]}
              width="auto"
              height="200"
              alt={image.fileAlt}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }
};
