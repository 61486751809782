export default (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "IS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "SET_MOBILE_NAV_STATE":
      return {
        ...state,
        mobileNavState: action.payload,
      };
    case "BLANK_PRESENTATION_COMPONENTS":
      return {
        ...state,
        blankPresentationComponents: action.payload,
      };
    default:
      return state;
  }
};
