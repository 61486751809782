import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../components/assets/Logo";

export const LoginFooter = ({ setFooterHeight }) => {
  const date = new Date().getFullYear();

  return (
    <footer id="login-footer" className="content-info">
      <div className="container-fluid">
        <div className="d-flex justify-content-between flex-column flex-md-row">
          <Logo width={147} height={60} />
          <div className="footer-links">
            <Nav>
              <Nav.Item>
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://rebelware.co" target="_blank">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://rebelware.co" target="_blank">
                  Pricing
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="copyright">
              <p className="text-white mb-0">
                <small>{`Spark by RebelWare © ${date} - All Rights Reserved`}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
