import React, { useState, useEffect } from "react";
import dompurify from "dompurify";
import { Card } from "react-bootstrap";
import { EditBar } from "./EditBar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import TrashIcon from "../../../../components/assets/TrashIcon";
import { ServiceSelect } from "./select/ServiceSelect";

export const ServicesModule = ({
  name,
  draggable,
  dragHandle,
  setData,
  data,
  deletable,
  componentId,
  initialData,
  setFilteredModules,
  filteredModules,
}) => {
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState(true);
  const sanitizer = dompurify.sanitize;
  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(selected);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelected(items);
  }

  function handleDeleteSelect(id) {
    setSelected(selected.filter((item) => item._id !== id));
  }

  useEffect(() => {
    setData({
      ...data,
      [componentId]: {
        active: active,
        services: selected,
      },
    });
  }, [active, selected]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setSelected(initialData.services);
      setActive(initialData.active);
    }
  }, []);

  return (
    <Card className="component-card editor-module team-member-editor-module">
      <EditBar
        draggable={draggable}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Select Services</p>
      <ServiceSelect
        setSelected={setSelected}
        selectId="service"
        selected={selected}
      />
      <div className="editor-module-selected-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="service">
            {(provided) => (
              <ul
                className="editor-module-selected-list-inner"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {selected &&
                  selected.map((service, idx) => (
                    <Draggable
                      key={idx}
                      draggableId={`service-inner_${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          className="editor-module-selected-list-inner-item"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex align-items-center">
                            <div className="editor-module-selected-list-item-name">
                              <p>{service.title}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="transparent me-3"
                              onClick={() => handleDeleteSelect(service._id)}
                            >
                              <TrashIcon height={24} width={24} />
                            </button>
                            <div
                              className="reorder-icon"
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon height={24} width={24} />
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Card>
  );
};
