import React from "react";
import { Container, Modal } from "react-bootstrap";
import { PresentationPreview } from "./PresentationPreview";

export const PreviewModal = ({ show, title, onHide, id }) => {
  function handleClose() {
    const noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
    onHide(false);
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        aria-labelledby="save-template"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="save-template">{`Preview ${title}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PresentationPreview id={id} />
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};
