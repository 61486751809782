import dompurify from "dompurify";
// import Loader from "react-loader-spinner";
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getTeamMembers, deleteTeamMember } from "../../../../api/teamMembers";
import PencilIcon from "../../../components/assets/PencilIcon";
import TrashIcon from "../../../components/assets/TrashIcon";
import { CollectionList } from "../../../layouts/CollectionList";

export const ListTeamMembers = () => {
  let currentID = "";
  const sanitizer = dompurify.sanitize;
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    "getTeamMembers",
    getTeamMembers
  );

  function handleDeleteSelect(data) {
    currentID = data;
    mutate();
  }

  const { mutate } = useMutation(
    ["deleteTeamMember", currentID],
    () => deleteTeamMember(currentID),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTeamMembers");
      },
    }
  );

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading team members</h4>;
  } else {
    return (
      <CollectionList
        title="View / Edit Team Members"
        loading={isLoading}
        error={isError}
      >
        {data.map((teamMember) => (
          <div className="collection-list-card" key={teamMember._id}>
            {/* {teamMember.image ?
          <div className="case-study-list-image">
          </div>
          : null
        } */}
            <div className="collection-list-col collection-list-image">
              {teamMember.image ? (
                teamMember.image.map((img, idx) => (
                  <img
                    key={`img_${idx}`}
                    width={133}
                    height={200}
                    alt={img.fileAlt}
                    src={img.fileUrl[3]}
                  />
                ))
              ) : (
                <img
                  width={133}
                  height={200}
                  alt="team member placeholder"
                  src="https://via.placeholder.com/350x525"
                />
              )}
            </div>
            <div className="collection-list-col collection-list-info">
              <div className="mb-sm-4">
                <p>{teamMember.name}</p>
                <p>{teamMember.title}</p>
              </div>
              <div>
                <p>{teamMember.email}</p>
                <p>{teamMember.phone}</p>
              </div>
            </div>
            <div className="collection-list-col collection-list-textarea">
              <div
                dangerouslySetInnerHTML={{ __html: sanitizer(teamMember.bio) }}
              ></div>
              {/* <div className="d-flex">
            <div className="me-4 d-flex">
              <p className="me-2 fw-bold">Departments:</p>
              {teamMember.department.map((item, i) => (
                <div className="me-2" key={`dept_${i}`}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className="d-flex">
              <p className="me-2 fw-bold">Industries:</p>
              {teamMember.industry.map((item, i) => (
                <div className="me-2" key={`ind_${i}`}>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div> */}
            </div>
            <div className="collection-list-buttons">
              <Link to={{ pathname: `/team-members/edit/${teamMember._id}` }}>
                <PencilIcon width="1rem" height="1rem" />
              </Link>
              <button
                className="transparent"
                onClick={() => handleDeleteSelect(teamMember._id)}
              >
                <TrashIcon width="1rem" height="1rem" />
              </button>
            </div>
          </div>
        ))}
      </CollectionList>
    );
  }
};
