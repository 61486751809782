import api from "./api";

export const createNewCaseStudy = async (payload) => {
  const data = await api.post("/case-studies/create", payload);
  return data;
};

export const getCaseStudies = async () => {
  const { data } = await api.get("/case-studies");
  return data;
};

export const getCaseStudy = async (id) => {
  const { data } = await api.get(`/case-studies/${id}`);
  return data;
};

export const deleteCaseStudy = async (id) => {
  const data = await api.delete(`/case-studies/delete/${id}`);
  return data;
};

export const editCaseStudy = async (payload) => {
  const data = await api.put("/case-studies/edit", payload);
  return data;
};
