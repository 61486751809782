import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import { getTeamMember, editTeamMember } from "../../../api/teamMembers";
// import DepartmentSelect from "../../components/DepartmentSelect";
// import IndustrySelect from "../../components/IndustrySelect";
import { AddMediaComponent } from "../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../layouts/CollectionCreate";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import { Helmet } from "../../layouts/Helmet";

export const EditTeamMember = () => {
  const { user } = useAuth0();
  // const [department, setDepartment] = useState([])
  // const [industry, setIndustry] = useState([])
  // const [currentDepartment, setCurrentDepartment] = useState([])
  // const [currentIndustry, setCurrentIndustry] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [bio, setBio] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [inputValue, setInputValue] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/");
  const teamMemberID = path[path.length - 1];
  const { data } = useQuery(["getTeamMember", teamMemberID], () =>
    getTeamMember(teamMemberID)
  );

  const { mutate, isLoading, isError } = useMutation(editTeamMember, {
    onSuccess: (data) => {
      queryClient.setQueryData(["getTeamMembers", { id: teamMemberID }], data);
    },
  });

  // set values on load
  useEffect(() => {
    if (data) {
      setSelectedImage(data[0].image);
      setOriginalName(data[0].name);
      setInputValue({
        title: data[0].title,
        name: data[0].name,
        email: data[0].email,
        phone: data[0].phone,
      });
      if (data[0].bio) {
        setBio(data[0].bio);
      }
      // if (data[0].department) {
      //   let dept = []
      //   data[0].department.map(p => (
      //     dept.push({ value: p, label: p })
      //   ))
      //   setCurrentDepartment(dept)
      //   console.log("ecs 63", currentDepartment)
      // }
      // if (data[0].industry) {
      //   let ind = []
      //   data[0].industry.map(p => (
      //     ind.push({ value: p, label: p })
      //   ))
      //   setCurrentIndustry(ind)
      // }
    }
  }, [data]);

  // update inputs
  function handleInput(e) {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // console.log(department)
    // let newDeptArr = []
    // if (department) {
    //   department.map(dept => {
    //     newDeptArr.push(dept.value)
    //   })
    // }
    // console.log(newDeptArr)
    // let newIndArr = []
    // if (industry) {
    //   industry.map(ind => {
    //     newIndArr.push(ind.value)
    //   })
    // }

    mutate({
      id: teamMemberID,
      image: selectedImage,
      title: inputValue.title,
      name: inputValue.name,
      email: inputValue.email,
      phone: inputValue.phone,
      // department: newDeptArr,
      // industry: newIndArr,
      bio: bio,
      user: user.sub,
    });
    navigate("/team-members");
  }
  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this team member</h4>;
  } else {
    return (
      <ScreenWrapper importIcon={false} screenTitle={`Edit ${originalName}`}>
        <Helmet metaTitle="Edit Team Member | Spark | RebelWare" />

        <CollectionCreate>
          <AddMediaComponent
            imageSelect={setSelectedImage}
            selectedImage={selectedImage}
            message="Recommended Image size is 350px x 525px"
          />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Name"
                  placeholder="Enter Name"
                  value={inputValue.name}
                  onChange={handleInput}
                  name="name"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Title"
                  placeholder="Enter Title"
                  value={inputValue.title}
                  onChange={handleInput}
                  name="title"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="email"
                  className="form-control"
                  aria-label="email"
                  placeholder="Enter Email"
                  value={inputValue.email}
                  onChange={handleInput}
                  name="email"
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Control
                  type="phone"
                  className="form-control"
                  aria-label="phone"
                  placeholder="Enter Phone Number (XXX-XXX-XXXX)"
                  value={inputValue.phone}
                  onChange={handleInput}
                  name="phone"
                />
              </Col>
            </Row>
            {/* <Row>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <DepartmentSelect currentState={currentDepartment} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <IndustrySelect currentState={currentIndustry} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
              </div>
            </Col>
          </Row> */}
            <Row>
              <Col md={12} className="mb-3">
                <ReactQuill theme="snow" value={bio} onChange={setBio} />
              </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-between">
              <Button
                as={Link}
                to="/team-members"
                variant="secondary"
                size="lg"
                type="button"
              >
                Cancel
              </Button>
              <Button variant="primary" size="lg" type="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </CollectionCreate>
      </ScreenWrapper>
    );
  }
};
