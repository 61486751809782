import React, { useState } from "react";
import { Modal, Button, Tab, Row, Col, Nav } from "react-bootstrap";
import { addableModules } from "../../components";
import XIcon from "../../../../../components/assets/XIcon";
import placeholder from "../../../../../../assets/images/placeholder-750-250.jpg";

export const AddModuleModal = ({ setAddedModules, usedModules, ...rest }) => {
  const [active, setActive] = useState();
  function addModule(e) {
    const id = e.target.value;
    setAddedModules(id);
    rest.onHide();
  }

  function handleClose() {
    rest.onHide();
  }

  function handleTabClick(e) {
    setActive(e.target.id);
  }

  return (
    <Modal
      {...rest}
      size="xl"
      aria-labelledby="add-modules"
      centered
      className="add-module-modal"
    >
      <Modal.Body>
        <Tab.Container id="add-module" defaultActiveKey="initial">
          <Row>
            <Col md={3} className="module-list-tabs-container order-2">
              <Nav className="flex-column">
                {addableModules.map((tab, idx) => (
                  <Nav.Item key={idx}>
                    <Nav.Link
                      id={idx}
                      className={`${active === idx ? "active" : ""}`}
                      eventKey={tab.name}
                      onClick={handleTabClick}
                      disabled={usedModules.some(
                        (item) => String(item.id) === String(tab.id)
                      )}
                    >
                      {tab.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                {/* <Tabs /> */}
              </Nav>
            </Col>
            <Col
              md={9}
              className="module-list-module-container order-1 order-md-3"
            >
              <Tab.Content>
                {addableModules.map((component, idx) => (
                  <Tab.Pane key={idx} eventKey={component.name}>
                    <div className="module-list-module-container-header">
                      <h2>{component.name}</h2>
                      <button className="transparent" onClick={handleClose}>
                        <XIcon width={24} height={24} />
                      </button>
                    </div>
                    <div className="module-list-module-container-body">
                      <p>
                        Select from a list of modules below. Once added the
                        module will appear at the end of your list. You can then
                        rearrange it by clicking and draging the module’s drag
                        icon.
                      </p>
                    </div>
                    <figure className="module-list-module-container-image">
                      <img
                        src={placeholder}
                        alt="placeholder"
                        width="100%"
                        height="auto"
                      />
                    </figure>
                    <Button
                      variant="primary"
                      value={component.id}
                      onClick={addModule}
                    >
                      Add Module
                    </Button>
                  </Tab.Pane>
                ))}
                <Tab.Pane key={111} eventKey="initial">
                  <div className="module-list-module-container-header">
                    <h2>Add Modules</h2>
                    <button className="transparent" onClick={handleClose}>
                      <XIcon width={24} height={24} />
                    </button>
                  </div>
                  <div className="module-list-module-container-body">
                    <p>
                      Select a module from the list to add it to the
                      presentaion.
                    </p>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};
