import moment from "moment";
import React, { useState, useEffect } from "react";
import { ExpirationNever } from "./ExpirationNever";
import { ExpirationSelect } from "./ExpirationSelect";

export const SelectExpirationStatus = ({
  publishDate,
  setExpirationDate,
  expirationDate,
  isNew,
}) => {
  const [status, setStatus] = useState("never");
  const [savedExpirationDate, setSavedExpirationDate] = useState();
  const [newExpirationDate, setNewExpirationDate] = useState();
  const currentDate = moment().format();

  useEffect(() => {
    if (expirationDate === null) {
      setSavedExpirationDate(moment());
    } else {
      setSavedExpirationDate(expirationDate);
    }
    if (isNew === true) {
      setStatus("never");
    } else {
      if (expirationDate > currentDate || expirationDate !== null) {
        setStatus("expires");
      } else {
        setStatus("never");
      }
    }
  }, [isNew]);

  useEffect(() => {
    if (status === "never") {
      setNewExpirationDate(null);
    } else {
      setNewExpirationDate(savedExpirationDate);
    }
  }, [status]);

  useEffect(() => {
    setExpirationDate(newExpirationDate);
  }, [newExpirationDate]);

  console.log("new Exp", newExpirationDate);

  return (
    <div className="rws-radio-group">
      <ExpirationNever setStatus={setStatus} status={status} />
      <ExpirationSelect
        status={status}
        setStatus={setStatus}
        publishDate={publishDate}
        savedExpirationDate={savedExpirationDate}
        newExpirationDate={newExpirationDate}
        setNewExpirationDate={setNewExpirationDate}
      />
    </div>
  );
};
