import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { addableModules } from "./backend/components";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { createNewPresentation } from "../../../api/presentations";
import { PublishBar } from "./backend/editor/PublishBar";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { Helmet } from "../../layouts/Helmet";

export const CreateFromExistingLayout = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const layout = location.state.template.layout;
  const template = location.state.template;
  const navigate = useNavigate();
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState();
  const filteredModules = [];
  const { user } = useAuth0();

  // save presentation
  const { mutate: mutateNewPresentation, isLoading } = useMutation(
    createNewPresentation,
    {
      onSuccess: (postData) => {
        queryClient.invalidateQueries("getPresentations");

        if (!isLoading) {
          navigate("/");
        }
      },
      onError: (response) => {
        console.log(response);
      },
    }
  );

  template.layout.forEach((pres) => {
    addableModules.forEach((item) => {
      if (pres === item.id) {
        filteredModules.push(item);
      }
    });
  });

  function savePresentation() {
    if (!presentationData.presentationTitle) {
      alert("please enter a title");
    } else {
      mutateNewPresentation({
        status: presentationData.status,
        presentationTitle: presentationData.presentationTitle,
        slug: presentationData.permalink,
        visibility: presentationData.visibility,
        password: presentationData.password,
        publishDate: presentationData.publishDate,
        expirationDate: presentationData.expirationDate,
        layout: layout,
        data: moduleData,
        author: user.name,
        user: user.sub,
      });
    }
  }

  // function saveDraftPresentation(status) {
  //   mutateDraftPresentation({
  //     status: status,
  //     presentationTitle: presentationData.presentationTitle,
  //     slug: presentationData.permalink,
  //     visibility: presentationData.visibility,
  //     password: presentationData.password,
  //     publishDate: presentationData.publishDate,
  //     expirationDate: presentationData.expirationDate,
  //     layout: layout,
  //     data: moduleData,
  //     author: user.name,
  //     user: user.sub,
  //   });
  // }

  return (
    <PresentationEditWrapper title={`New ${template.title}`}>
      <Helmet metaTitle={`${template.title} | Spark | RebelWare`} />
      <PublishBar savePresentation={savePresentation} />

      <PresentationInformation
        setData={setPresentationData}
        data={presentationData}
      />
      <ClientInfoModule setData={setModuleData} data={moduleData} />
      <div className="presentation-module-list">
        <ul>
          {filteredModules.map((component, idx) => (
            <li key={`editor_${idx}`} className="presentation-edit-module">
              <component.component
                setData={setModuleData}
                data={moduleData}
                draggable={false}
                name={component.name}
                componentId={component.id}
              />
            </li>
          ))}
        </ul>
      </div>
    </PresentationEditWrapper>
  );
};
