import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditBar } from "./EditBar";
import TrashIcon from "../../../../components/assets/TrashIcon";
import ReorderIcon from "../../../../components/assets/ReorderIcon";
import placeholder from "../../../../../assets/images/500x500-image-placeholder.jpg";
import { AddMediaComponent } from "../../../../components/media/AddMediaComponent";

export const ImageModule = ({
  name,
  draggable,
  deletable,
  dragHandle,
  setData,
  data,
  componentId,
  initialData,
  setFilteredModules,
  filteredModules,
}) => {
  const [selected, setSelected] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [active, setActive] = useState(true);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(imageArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setImageArray(items);
  }

  useEffect(() => {
    selected.forEach((sel) => {
      setImageArray((prev) => [...prev, sel]);
    });
  }, [selected]);

  function handleDeleteSelect(idx) {
    setImageArray(imageArray.filter((item, i) => i !== idx));
  }

  useEffect(() => {
    setData({
      ...data,
      [componentId]: {
        active: active,
        images: imageArray,
      },
    });
  }, [active, imageArray]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setImageArray(initialData.images);
      setActive(initialData.active);
    }
  }, []);

  return (
    <Card className="component-card editor-module image-editor-module">
      <EditBar
        draggable={draggable}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Image Select</p>
      <AddMediaComponent
        imageSelect={setSelected}
        selectedImage={selected}
        multiple={true}
        showImage={false}
      />
      <div className="editor-module-selected-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="images">
            {(provided) => (
              <ul
                className="editor-module-selected-list-inner"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {imageArray &&
                  imageArray.map((image, idx) => (
                    <Draggable
                      key={idx}
                      draggableId={`images-inner_${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          className="editor-module-selected-list-inner-item"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex align-items-center">
                            <div className="editor-module-selected-list-item-image">
                              <img
                                src={
                                  image.image ? image.fileUrl[1] : placeholder
                                }
                                alt={
                                  image.image
                                    ? image.fileAlt
                                    : "placeholder-image"
                                }
                                width={40}
                                height={40}
                              />
                            </div>
                            <div className="editor-module-selected-list-item-name">
                              <p>{image.fileName}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="transparent me-3"
                              onClick={() => handleDeleteSelect(idx)}
                            >
                              <TrashIcon height={24} width={24} />
                            </button>
                            <div
                              className="reorder-icon"
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon height={24} width={24} />
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Card>
  );
};
