import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";
import { addableModules } from "./backend/components";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { AddModuleModal } from "./backend/editor/modals/AddModuleModal";
import SaveTemplateModal from "./backend/editor/modals/SaveTemplateModal";
import { PublishBar } from "./backend/editor/PublishBar";
import { createNewPresentation } from "../../../api/presentations";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { useNavigate } from "react-router-dom";
import { Helmet } from "../../layouts/Helmet";

export const CreateFromBlankTemplate = (props) => {
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState();
  const [addModuleShow, setAddModuleShow] = useState(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [addedModules, setAddedModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [componentIds, setComponentIds] = useState(["client"]);
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // save presentation
  const { mutate: mutateNewPresentation } = useMutation(createNewPresentation, {
    onSuccess: (postData) => {
      queryClient.invalidateQueries("getPresentations");
      navigate("/");
    },
    onError: (response) => {
      console.log(response);
    },
  });

  // as modules are added, set array to be mapped through and displayed and set IDs to array to be saved
  useEffect(() => {
    addableModules.forEach((item) => {
      if (addedModules === item.id) {
        setFilteredModules((prev) => [...prev, item]);
        setComponentIds((prev) => [...prev, item.id]);
      }
    });
  }, [addedModules]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(filteredModules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredModules(items);
  }

  function savePresentation() {
    if (!presentationData.presentationTitle) {
      alert("please enter a title");
    } else {
      mutateNewPresentation({
        status: presentationData.status,
        presentationTitle: presentationData.presentationTitle,
        slug: presentationData.permalink,
        visibility: presentationData.visibility,
        password: presentationData.password,
        publishDate: presentationData.publishDate,
        expirationDate: presentationData.expirationDate,
        layout: componentIds,
        data: moduleData,
        author: user.name,
        user: user.sub,
      });
    }
  }
  return (
    <PresentationEditWrapper title="Create New Presentation">
      <Helmet metaTitle="Blank Template | Spark | RebelWare" />
      <PublishBar
        savePresentation={savePresentation}
        setAddModuleShow={setAddModuleShow}
        setSaveTemplateShow={setSaveTemplateShow}
      />
      <PresentationInformation
        setData={setPresentationData}
        data={presentationData}
      />
      <ClientInfoModule setData={setModuleData} data={moduleData} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="presentation-module-list">
          <Droppable droppableId="existing-presentation">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {filteredModules.map((component, idx) => (
                  <Draggable
                    key={`editor_${idx}`}
                    draggableId={`drag_${idx}`}
                    index={idx}
                  >
                    {(provided) => (
                      <li
                        className="presentation-edit-module"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <component.component
                          setData={setModuleData}
                          data={moduleData}
                          draggable={true}
                          setFilteredModules={setFilteredModules}
                          filteredModules={filteredModules}
                          dragHandle={provided.dragHandleProps}
                          name={component.name}
                          componentId={component.id}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <AddModuleModal
        show={addModuleShow}
        onHide={() => setAddModuleShow(false)}
        setAddedModules={setAddedModules}
        usedModules={filteredModules}
      />
      <SaveTemplateModal
        template={filteredModules}
        show={saveTemplateShow}
        onHide={() => setSaveTemplateShow(false)}
      />
    </PresentationEditWrapper>
  );
};
