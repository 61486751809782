import React from "react";
import { CreateTestimonial } from "./modules/CreateTestimonial";
import { ListTestimonials } from "./modules/ListTestimonials";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";

export const Testimonials = () => {
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {},
      });
    }
  }
  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Testimonials"
    >
      <Helmet metaTitle="Testimonials | Spark | RebelWare" />
      <CreateTestimonial />
      <ListTestimonials />
    </ScreenWrapper>
  );
};
