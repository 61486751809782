import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditBar } from "./EditBar";
import TrashIcon from "../../../../components/assets/TrashIcon";
import { AddVideoComponent } from "../../../../components/media/AddVideoComponent";
import ReorderIcon from "../../../../components/assets/ReorderIcon";

export const VideoModule = ({
  name,
  dragHandle,
  draggable,
  deletable,
  setData,
  data,
  componentId,
  initialData,
  setFilteredModules,
  filteredModules,
}) => {
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState(true);
  const [videoArray, setVideoArray] = useState([]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(videoArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVideoArray(items);
  }

  useEffect(() => {
    selected.forEach((sel) => {
      setVideoArray((prev) => [...prev, sel]);
    });
  }, [selected]);

  function handleDeleteSelect(idx) {
    setVideoArray(videoArray.filter((item, i) => i !== idx));
  }

  useEffect(() => {
    setData({
      ...data,
      [componentId]: {
        active: active,
        videos: videoArray,
      },
    });
  }, [active, videoArray]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setVideoArray(initialData.videos);
      setActive(initialData.active);
    }
  }, []);

  return (
    <Card className="component-card editor-module video-editor-module">
      <EditBar
        draggable={draggable}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Video Select</p>
      <AddVideoComponent
        videoSelect={setSelected}
        selectedVideo={selected}
        multiple={true}
        showVideo={false}
      />
      <div className="editor-module-selected-list">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="videos">
            {(provided) => (
              <ul
                className="editor-module-selected-list-inner"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {videoArray &&
                  videoArray.map((doc, idx) => (
                    <Draggable
                      key={idx}
                      draggableId={`videos-inner_${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <li
                          className="editor-module-selected-list-inner-item"
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <div className="d-flex align-items-center">
                            <div className="editor-module-selected-list-item-name">
                              <p>{doc.fileName}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button
                              className="transparent me-3"
                              onClick={() => handleDeleteSelect(idx)}
                            >
                              <TrashIcon height={24} width={24} />
                            </button>
                            <div
                              className="reorder-icon"
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon height={24} width={24} />
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Card>
  );
};
