import moment from "moment";
import React, { useCallback, useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import DatePickerModal from "../modals/DatePickerModal";
import CalendarIcon from "../../../../../components/assets/CalendarIcon";
import { SelectVisibility } from "./SelectVisibility";
import { SelectPublishStatus } from "./publishStatus/SelectPublishStatus";
import { SelectExpirationStatus } from "./expirationStatus/SelectExpirationStatus";

export const PresentationInformation = ({ setData, initialData }) => {
  const [publishDate, setPublishDate] = useState(moment().format());
  const [isNew, setIsNew] = useState();
  const [expirationDate, setExpirationDate] = useState("");
  const [title, setTitle] = useState("");
  const [permalink, setPermalink] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [password, setPassword] = useState("");
  const [publishStatus, setPublishStatus] = useState("published");

  // Send data to layout to be compiled with the data from other components
  useEffect(() => {
    setData({
      status: publishStatus,
      presentationTitle: title,
      visibility: visibility,
      publishDate: publishDate,
      expirationDate: expirationDate,
      permalink: permalink,
      password: password,
    });
  }, [
    title,
    visibility,
    publishDate,
    expirationDate,
    permalink,
    password,
    publishStatus,
  ]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setPublishStatus(initialData.status);
      setTitle(initialData.presentationTitle);
      setVisibility(initialData.visibility);
      setPublishDate(initialData.publishDate);
      setExpirationDate(initialData.expirationDate);
      setPermalink(initialData.slug);
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, []);

  return (
    <Card className="presentation-information-editor-module editor-module">
      <div className="mb-medium">
        <h2 className="mb-3">Presentation Info &amp; Settings</h2>
        <input
          type="text"
          className="form-control"
          aria-label="Presentation Name"
          placeholder="Enter Presentation Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          name="presentationName"
        />
      </div>
      <div className="mb-medium">
        <p className="mb-3 editor-module-field-label">visibility:</p>
        <SelectVisibility
          setVisibility={setVisibility}
          visibility={visibility}
          setPassword={setPassword}
          password={password}
        />
      </div>
      <div className="mb-medium">
        <p className="mb-3 editor-module-field-label">Publish Status:</p>
        <SelectPublishStatus
          setPublishDate={setPublishDate}
          publishDate={publishDate}
          setPublishStatus={setPublishStatus}
          publishStatus={publishStatus}
          expirationDate={expirationDate}
          isNew={isNew}
        />
      </div>
      <div className="mb-medium">
        <p className="mb-3 editor-module-field-label">Expiration Status:</p>
        <SelectExpirationStatus
          publishDate={publishDate}
          setExpirationDate={setExpirationDate}
          expirationDate={expirationDate}
          isNew={isNew}
        />
      </div>
      <div className="presentation-information-tags">
        <p className="mb-3 editor-module-field-label">Permalink:</p>
        <input
          type="text"
          className="form-control mb-2"
          aria-label="Slug"
          placeholder="my-custom-slug"
          value={permalink}
          onChange={(e) => setPermalink(e.target.value)}
          name="presentationName"
        />
        <p className="editor-module-field-label small">
          If permalink is left blank, the title will be used
        </p>
      </div>
    </Card>
  );
};
