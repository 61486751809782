import React, { useState } from "react";
import { DashboardWrapper } from "../../layouts/DashboardWrapper";
import { PresentationList } from "./presentation-list/PresentationList";
import { PresentationFilter } from "./filter/PresentationFilter";
import { PresentationLegend } from "./PresentationLegend";
import { Helmet } from "../../layouts/Helmet";
// import { Helmet } from "react-helmet";

export const Dashboard = () => {
  const [presentationData, setPresentationData] = useState();
  const [viewFilteredData, setViewFilteredData] = useState();
  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <DashboardWrapper title="Dashboard">
        <Helmet metaTitle="Dashboard | Spark | RebelWare" />
        <PresentationFilter
          presentationData={presentationData}
          setViewFilteredData={setViewFilteredData}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
        />
        <PresentationLegend />
        <PresentationList
          setPresentationData={setPresentationData}
          viewFilteredData={viewFilteredData}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </DashboardWrapper>
    </>
  );
};
