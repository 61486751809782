import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addableModules } from "./backend/components";
import { ClientInfoModule } from "./backend/editor/ClientInfoModule";
import { PresentationInformation } from "./backend/editor/presentationInformation/PresentationInformation";
import { AddModuleModal } from "./backend/editor/modals/AddModuleModal";
import SaveTemplateModal from "./backend/editor/modals/SaveTemplateModal";
import { PublishBar } from "./backend/editor/PublishBar";
import { editPresentation, getPresentation } from "../../../api/presentations";
import { PresentationEditWrapper } from "../../layouts/PresentationEditWrapper";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export const EditPresentation = (props) => {
  const [presentationData, setPresentationData] = useState();
  const [moduleData, setModuleData] = useState();
  const [addModuleShow, setAddModuleShow] = useState(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [addedModules, setAddedModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [componentIds, setComponentIds] = useState(["client"]);
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const path = window.location.pathname.split("/");
  const presentationID = path[path.length - 1];
  const navigate = useNavigate();
  let template;

  // get presentation query
  const { data, isLoading, isError } = useQuery(
    ["getPresentation", presentationID],
    () => getPresentation(presentationID)
  );

  // save presentation
  const { mutate } = useMutation(editPresentation, {
    onSuccess: (data) => {
      // queryClient.setQueryData(
      //   ["getPresentations", { id: presentationID }],
      //   data
      // );
      queryClient.invalidateQueries("getPresentations");
      queryClient.invalidateQueries("getPresentation");
      navigate("/");
    },
    onError: (response) => {
      console.log(response);
    },
  });

  // grab initial layout from presentation data and match against components to display current template layout
  // set presentation data set to queried data. this just a baseline. Still need to push the initial data to the components, but this way when items arent edited, the data is still there
  useEffect(() => {
    if (data) {
      template = data[0].layout;
      setComponentIds(template);
      setModuleData(data[0].data);
      template.forEach((pres) => {
        addableModules.forEach((item) => {
          if (pres === item.id) {
            setFilteredModules((prev) => [...prev, item]);
          }
        });
      });
    }
  }, [isLoading]);
  // as modules are added, set array to be mapped through and displayed and set IDs to array to be saved
  useEffect(() => {
    addableModules.forEach((item) => {
      if (addedModules === item.id) {
        setFilteredModules((prev) => [...prev, item]);
        setComponentIds((prev) => [...prev, item.id]);
      }
    });
  }, [addedModules]);

  function handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(filteredModules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilteredModules(items);
  }
  console.log("presentationData", presentationData);
  // save edited presentation
  function savePresentation() {
    if (!presentationData.presentationTitle) {
      alert("please enter a title");
    } else {
      mutate({
        id: presentationID,
        status: presentationData.status,
        presentationTitle: presentationData.presentationTitle,
        slug: presentationData.permalink,
        visibility: presentationData.visibility,
        password: presentationData.password,
        publishDate: presentationData.publishDate,
        expirationDate: presentationData.expirationDate,
        layout: componentIds,
        data: moduleData,
        author: user.name,
        user: user.sub,
      });
    }
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this presentation</h4>;
  } else if (!data) {
    return <h4>There was an error loading data for this presentation</h4>;
  } else {
    console.log("data", data[0]);
    return (
      <>
        <PresentationEditWrapper title={`Edit ${data[0].presentationTitle}`}>
          <PublishBar
            savePresentation={savePresentation}
            setAddModuleShow={setAddModuleShow}
          />
          <PresentationInformation
            setData={setPresentationData}
            initialData={data[0]}
          />
          <ClientInfoModule
            setData={setModuleData}
            data={moduleData}
            initialData={data[0].data.client}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="presentation-module-list">
              <Droppable droppableId="existing-presentation">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredModules.map((component, idx) => (
                      <Draggable
                        key={`editor_${idx}`}
                        draggableId={`drag_${idx}`}
                        index={idx}
                      >
                        {(provided) => (
                          <li
                            className="presentation-edit-module"
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <component.component
                              setData={setModuleData}
                              data={moduleData}
                              initialData={data[0].data[component.id]}
                              draggable={true}
                              deletable={true}
                              dragHandle={provided.dragHandleProps}
                              name={component.name}
                              componentId={component.id}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <AddModuleModal
            show={addModuleShow}
            onHide={() => setAddModuleShow(false)}
            setAddedModules={setAddedModules}
            usedModules={filteredModules}
          />
          <SaveTemplateModal
            templateData={template}
            template={filteredModules}
            show={saveTemplateShow}
            onHide={() => setSaveTemplateShow(false)}
          />
        </PresentationEditWrapper>
      </>
    );
  }
};
