import React from "react";

export const CollectionCreate = (props) => {
  return (
    <div className="create-collection">
      {props.title ? (
        <h2 className="create-collection-title">{props.title}</h2>
      ) : null}
      {props.children}
    </div>
  );
};
