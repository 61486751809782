import api from "./api";

export const createNewService = async (payload) => {
  const data = await api.post("/services/create", payload);
  return data;
};

export const getServices = async () => {
  const { data } = await api.get("/services");
  return data;
};

export const getService = async (id) => {
  const { data } = await api.get(`/services/${id}`);
  return data;
};

export const deleteService = async (id) => {
  const data = await api.delete(`/services/delete/${id}`);
  return data;
};

export const editService = async (payload) => {
  const data = await api.put("/services/edit", payload);
  return data;
};
