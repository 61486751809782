import React from "react";
import CreateCaseStudy from "./modules/CreateCaseStudy";
import ListCaseStudies from "./modules/ListCaseStudies";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";

function CaseStudies() {
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {},
      });
    }
  }
  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Case Studies"
    >
      <Helmet metaTitle="Case Studies | Spark | RebelWare" />

      <CreateCaseStudy />
      <ListCaseStudies />
    </ScreenWrapper>
  );
}

export default CaseStudies;
