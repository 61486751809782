import React from "react";
import { Form } from "react-bootstrap";

export const SearchFilter = ({ setSearchInput, searchInput }) => {
  function handleSearchInput(e) {
    setSearchInput(e.target.value);
  }
  return (
    <>
      <h2 className="mb-3">Search</h2>
      <Form.Control
        type="text"
        value={searchInput}
        placeholder="Type to filter results below"
        onChange={handleSearchInput}
      />
    </>
  );
};
