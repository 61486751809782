import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import { EditBar } from "./EditBar";

export const TextModule = ({
  name,
  dragHandle,
  draggable,
  deletable,
  setData,
  data,
  componentId,
  initialData,
  setFilteredModules,
  filteredModules,
}) => {
  const [active, setActive] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    setData({
      ...data,
      [componentId]: {
        active: active,
        text: text,
      },
    });
  }, [active, text]);

  // if edit, set the initial data from query
  useEffect(() => {
    if (initialData) {
      setText(initialData.text);
      setActive(initialData.active);
    }
  }, []);

  return (
    <Card className="component-card editor-module team-member-editor-module">
      <EditBar
        draggable={draggable}
        active={active}
        setActive={setActive}
        dragHandle={dragHandle}
        setFilteredModules={setFilteredModules}
        filteredModules={filteredModules}
        componentId={componentId}
      />
      <h2 className="mb-3">{name}</h2>
      <p className="editor-module-field-label">Add Custom Text</p>
      <div className="mb-3">
        <ReactQuill
          theme="snow"
          value={text}
          onChange={setText}
          placeholder={"Add Text"}
        />
      </div>
    </Card>
  );
};
