import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { createNewTestimonial } from "../../../../api/testimonials";
// import DepartmentSelect from "../../../components/DepartmentSelect";
// import IndustrySelect from "../../../components/IndustrySelect";
import { CollectionCreate } from "../../../layouts/CollectionCreate";

export const CreateTestimonial = () => {
  const { user } = useAuth0();
  const [text, setText] = useState("");
  // const [clear, setClear] = useState("")
  // const [department, setDepartment] = useState([]);
  // const [selectedDepartment, setSelectedDepartment] = useState([]);
  // const [industry, setIndustry] = useState([]);
  // const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [input, setInput] = useState({
    title: "",
    name: "",
    company: "",
  });

  const queryClient = useQueryClient();

  const { mutate, isError } = useMutation(createNewTestimonial, {
    onSuccess: (newTestimonial) => {
      const data = newTestimonial.data.Testimonial;
      queryClient.setQueryData("getTestimonials", (prev) => [...prev, data]);
      setText("");
      // setClear("clear")
      // setSelectedDepartment([])
      // setDepartment([])
      // setSelectedIndustry([])
      // setIndustry([])
      setInput({
        title: "",
        name: "",
        company: "",
      });
    },
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   let dept = [];
  //   if (department) {
  //     department.map(p => {
  //       dept.push(p.value)
  //     })
  //   }
  //   setSelectedDepartment(dept)
  //
  // }, [department])

  // useEffect(() => {
  //   let ind = [];
  //   if (industry) {
  //     industry.map(p => {
  //       ind.push(p.value)
  //     })
  //   }
  //   setSelectedIndustry(ind)
  //
  // }, [industry])

  function handleSubmit(e) {
    e.preventDefault();

    if (input.title === "") {
      alert("There is no title");
    } else {
      mutate({
        name: input.name,
        title: input.title,
        company: input.company,
        testimonial: text,
        user: user.sub,
        // department: selectedDepartment,
        // industry: selectedIndustry,
      });
    }
  }

  if (isError) {
    return <div>There has been an error</div>;
  }

  return (
    <CollectionCreate title="Create New Testimonial">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Title"
              placeholder="Enter Title"
              value={input.title}
              onChange={handleInput}
              name="title"
            />
          </Col>
          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Name"
              placeholder="Enter Name"
              value={input.name}
              onChange={handleInput}
              name="name"
            />
          </Col>

          <Col md={6} className="mb-3">
            <Form.Control
              type="text"
              className="form-control"
              aria-label="text"
              placeholder="Enter Company"
              value={input.company}
              onChange={handleInput}
              name="company"
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <DepartmentSelect clear={clear} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div className="multi-select-container">
              <IndustrySelect clear={clear} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="mb-3">
            <ReactQuill
              theme="snow"
              value={text}
              onChange={setText}
              placeholder={"Add Testimonial"}
            />
          </Col>
        </Row>
        <div className="mt-5">
          <Button variant="primary" size="lg" type="submit">
            Add Testimonial
          </Button>
        </div>
      </Form>
    </CollectionCreate>
  );
};
