import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import background from "../../../assets/images/login-bg.jpg";
import { AppContext } from "../../../context/AppContext";
import Logo from "../../components/assets/Logo";
import Footer from "../../partials/global/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginFooter } from "./LoginFooter";
import { LoginLayout } from "../../layouts/LoginLayout";
import { Helmet } from "../../layouts/Helmet";

function Login() {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  const navigate = useNavigate();
  // useEffect(() => {
  let footerHeight;
  const footer = document.getElementById("login-footer");
  if (footer) {
    footerHeight = footer.offsetHeight;
  }
  // }, []);

  // const [footerHeight, setFooterHeight] = useState();

  // useEffect(() => {
  //   // let accessToken;
  //   const getUserMetadata = async () => {
  //     const domain = "https://spark.alpha.rebelware.io";

  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         audience: "https://auth.rebelware.io",
  //         scope: "read:current_user",
  //       });
  //       console.log(accessToken);
  //       // const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

  //       // const metadataResponse = await fetch(userDetailsByIdUrl, {
  //       //   headers: {
  //       //     Authorization: `Bearer ${accessToken}`,
  //       //   },
  //       // });

  //       // const { user_metadata } = await metadataResponse.json();

  //       // setUserMetadata(user_metadata);
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };

  //   getUserMetadata();
  //   console.log("36", accessToken);
  // }, []);

  // const { setAuthenticated } = useContext(AppContext);
  const [creds, setCreds] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreds((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // async function handleEmailAuth(e) {
  //   e.preventDefault();
  //   api
  //     .post(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
  //       username: creds.username.toLowerCase(),
  //       password: creds.password,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (!res.data.auth) {
  //         Promise.reject(new Error("Whoops!"));
  //       }
  //       console.log(res.data);

  //       localStorage.setItem("token", res.data.token);
  //       Promise.resolve(true);
  //     })
  //     .then(function () {
  //       setAuthenticated();
  //       navigate("/");
  //     })
  //     .catch(function (err) {
  //       console.log("error" + err);
  //     });
  // }

  // const handleGoogleAuth = (response) => {
  //   console.log("google oAuth response", response);
  //   // let userAvatar = response.profileObj.imageUrl

  //   // console.log(userAvatar)

  //   // const token = response.accessToken;

  //   // localStorage.setItem("token", token)
  //   // setAuthenticated()
  //   api
  //     .post(`${process.env.REACT_APP_API_ENDPOINT}/login/google`, {
  //       data: response,
  //     })
  //     .then((res) => {
  //       console.log("api server response", res);
  //       localStorage.setItem("token", res.data.token);
  //     })
  //     .then(() => setAuthenticated())
  //     .then(() => navigate("/"))
  //     .catch((err) => console.log("log error:" + err));
  // };

  // const onFailure = (response) => console.error(response);

  function authLogin() {
    loginWithRedirect();
  }
  return (
    <LoginLayout>
      <Helmet metaTitle="Login | Spark | RebelWare" />
      <div
        className="login-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Container className="login-container">
          <Row
            className="login-row justify-content-center justify-content-lg-end"
            style={{ paddingBottom: footerHeight }}
          >
            <Col md={6} className="login-body">
              <Logo width={232} height={95} />
              <h1 className="text-white display-1 my-4"> Get Your Sell On</h1>
              <h2 className="text-white display-2 my-4">
                Spark, Ignite, Deliver, Close
              </h2>
              <h3 className="text-white display-3 my-4">
                <p>
                  Speed is critical when it comes to sales. Companies typically
                  take days to follow up with detailed information requests,
                  dramatically reducing conversions.
                </p>
                <p>
                  But imagine you could close the gap from multiple days to
                  minutes?
                </p>
                <p>
                  Spark is a fully customizable landing page-builder that was
                  built to do one thing — a rapid, consistent, branded,
                  engaging, and trackable way to send information to key
                  audiences.
                </p>
              </h3>
            </Col>
            <Col md={6}>
              <Card className="card-signin">
                <Card.Body>
                  <h2 className="card-signin-title">Sign In</h2>
                  <Form className="form-signin">
                    <FloatingLabel
                      className="mb-3"
                      controlId="signupEmail"
                      label="Email address"
                    >
                      <Form.Control
                        type="text"
                        required={true}
                        autoComplete="none"
                        placeholder="user@email.com"
                        value={creds.username.toLowerCase()}
                        onChange={handleChange}
                        name="username"
                      />
                    </FloatingLabel>{" "}
                    <FloatingLabel
                      className="mb-3"
                      controlId="signupPassword"
                      label="Password"
                    >
                      <Form.Control
                        type="password"
                        autoComplete="none"
                        placeholder="Enter Password"
                        value={creds.password}
                        onChange={handleChange}
                        name="password"
                      />
                    </FloatingLabel>{" "}
                    <div className="my-3">
                      <Button
                        type="submit"
                        variant="secondary"
                        // onClick={handleEmailAuth}
                      >
                        Sign In
                      </Button>
                    </div>
                    <div className="my-3">
                      <Button variant="primary" onClick={authLogin}>
                        Login with Google
                      </Button>
                      {/* <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        onSuccess={handleGoogleAuth}
                        onFailure={onFailure}
                        isSignedIn={true}
                        // cookiePolicy={"single_host_origin"}
                        // accessType="offline"
                        // responseType="code"
                        approvalPrompt="force"
                        prompt='consent'
                        render={renderProps => (
                          <Button variant="primary" onClick={renderProps.onClick}>Login With Google Account</Button>
                        )}
                      /> */}
                    </div>
                    <div className="my-3">
                      <Button type="submit" variant="link">
                        Forgot Password
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </LoginLayout>
  );
}

export default Login;
