import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createNewMedia, getMedia } from "../../../api/media";
import Library from "../../../media-library/Library";
import { Helmet } from "../../layouts/Helmet";

function MediaLibrary() {
  const queryClient = useQueryClient();

  // state
  const [mediaFilesToUpload, setMediaFilesToUpload] = useState([]);
  const [loadError, setLoadError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Initial Upload Mutation
  const { mutate: initialUpload } = useMutation(createNewMedia, {
    onSuccess: (newMedia) => {
      const data = newMedia.data.uploadResponse;
      data.forEach((file) => {
        queryClient.setQueryData("allMedia", (prev) => [...prev, file]);
      });

      setMediaFilesToUpload([]);
    },
  });

  // Get Media Query
  const { data, isLoading, isError } = useQuery("allMedia", getMedia, {
    onError: () => {
      setLoadError(true);
    },
    onSuccess: () => {
      setSuccess(true);
    },
  });

  // update media query

  // delete media query

  // set form data and mutate
  useEffect(() => {
    const formData = new FormData();
    if (mediaFilesToUpload && mediaFilesToUpload.length) {
      mediaFilesToUpload.forEach((file) => {
        formData.append("file", file);
        formData.append("name", file.name);
      });
      initialUpload(formData);
    }
  }, [mediaFilesToUpload]);

  // handle errors
  if (isError || loadError || !success) {
    return <>error</>;
  }

  // return if successful
  if (success) {
    return (
      <>
        <div className="screen-header">
          <h1>Media Library</h1>
        </div>

        <Library
          insertable={false}
          initialFiles={data}
          tabViewsTitles={["All Media", "Images", "Videos", "PDFs"]}
          uploadMultiple={true}
          onUpload={setMediaFilesToUpload}
          selectMultiple={false}
          isLoading={isLoading}
        />
        <Helmet metaTitle="Media Library | Spark | RebelWare" />
      </>
    );
  }
}

export default MediaLibrary;
