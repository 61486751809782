import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import InfoIcon from "../../../components/assets/InfoIcon";
import PencilIcon from "../../../components/assets/PencilIcon";
import TrashIcon from "../../../components/assets/TrashIcon";
import { PresentationListLabel } from "./PresentationListLabel";

export const PresentationListItem = ({
  presentation,
  handlePreview,
  handleDeleteSelect,
}) => {
  const createdDate = moment(presentation.createdAt).format("LL");
  const updatedDate = moment(presentation.createdAt).format("LL");
  const date = moment();
  const expiration = moment(presentation.expirationDate);
  const publish = moment(presentation.publishDate);

  let status = {
    status: "",
    class: "",
  };
  // const [status, setStatus] = useState("");
  // const [statusClass, setStatusClass] = useState("");
  if (!expiration.isValid()) {
    status = {
      status: "Never Expires",
      class: "quartary",
    };
  }
  if (expiration.format() < date.format()) {
    status = {
      status: "Expired",
      class: "primary",
    };
  }
  if (expiration > date) {
    status = {
      status: `${expiration.diff(date, "days")} Days`,
      class: "tertiary",
    };
  }
  if (presentation.status === "draft") {
    status = {
      status: "Draft",
      class: "medium",
    };
  }

  if (presentation.status === "published" && publish.format() > date.format()) {
    status = {
      status: "Scheduled",
      class: "secondary",
    };
  }

  console.log(expiration);
  // console.log(date);
  // console.log(expiration);
  return (
    <Card className="presentation-list-card">
      <PresentationListLabel status={status} />
      <div className="presentation-list-container">
        <div className="collection-list-col collection-list-info">
          <h2 className="presentation-list-title">
            {presentation.presentationTitle}
          </h2>
          <div className="presentation-list-details">
            <div className="presentation-list-created-date">
              <p className="light-font mb-2 mb-md-0">
                <strong>Created On: </strong>
                {createdDate}
              </p>
              {presentation.updatedAt > presentation.createdAt && (
                <p className="light-font mb-2 mb-md-0">
                  <strong>Last Updated: </strong>
                  {updatedDate}
                </p>
              )}
            </div>
            <div className="presentation-list-author">
              <p className="light-font mb-2 mb-md-0">
                <strong>Author: </strong>
                {presentation.author}
              </p>
            </div>
          </div>
        </div>
        <div className="collection-list-buttons">
          <button
            className="transparent"
            onClick={() => handlePreview(presentation)}
          >
            <InfoIcon width="1rem" height="1rem" />
          </button>
          <Link to={{ pathname: `/presentation/edit/${presentation._id}` }}>
            <PencilIcon width="1rem" height="1rem" />
          </Link>
          <button
            className="transparent"
            onClick={() => handleDeleteSelect(presentation._id)}
          >
            <TrashIcon width="1rem" height="1rem" />
          </button>
        </div>
      </div>
    </Card>
  );
};
