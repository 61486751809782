/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import pdfPlaceholder from "./components/assets/pdf-placeholder.png";
import videoPlaceholder from "./components/assets/video-placeholder.png";
import { FileInfo } from "./components/filters/FileInfo";
import { Search } from "./components/filters/Search";
import { AllMedia } from "./components/media-folders/AllMedia";
import { ImageMedia } from "./components/media-folders/ImageMedia";
import { PdfMedia } from "./components/media-folders/PdfMedia";
import { VideoMedia } from "./components/media-folders/VideoMedia";
import { Tabs } from "./components/tabs/Tabs";
import { MediaUpload } from "./components/upload/MediaUpload";

export default function Library(props) {
  const {
    tabViewsTitles,
    uploadMultiple,
    onUpload,
    initialFiles,
    selectMultiple,
    isLoading,
    insertable,
    selectedImage,
  } = props;

  const [activeTab, setActiveTab] = useState(tabViewsTitles[0]);
  const [latestVal, setLatestVal] = useState("");
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredFiles, setFilteredFiles] = useState([]);

  const allFiles = [];
  const pdf = [];
  const img = [];
  const vid = [];

  if (isLoading) {
    return <h2>Loading</h2>;
  } else {
    // filter for search
    useEffect(() => {
      setFilteredFiles(
        initialFiles.filter((name) =>
          name.fileName.toLowerCase().includes(search.toLocaleLowerCase())
        )
      );
    }, [search, initialFiles]);

    // seperate file types
    filteredFiles.forEach((file) => {
      if (file.fileType === "pdf") {
        Object.assign(file, { image: pdfPlaceholder });
        pdf.push(file);
        allFiles.push(file);
      }
      if (file.fileType === "mp4") {
        Object.assign(file, { image: videoPlaceholder });
        vid.push(file);
        allFiles.push(file);
      }
      if (
        file.fileType === "jpg" ||
        file.fileType === "jpeg" ||
        file.fileType === "png"
      ) {
        Object.assign(file, { image: file.fileUrl[2] });
        img.push(file);
        allFiles.push(file);
      }
    });
    return (
      <div id="rw_media_library">
        <Tabs titles={tabViewsTitles} currentTab={setActiveTab} />
        <div className="rw_media_library_inner_container">
          <MediaUpload multiple={uploadMultiple} uploadedFiles={onUpload} />
          <Search activeTab={activeTab} onSubmit={setSearch} />

          <div className="rw_media_Library_image_container">
            {filteredFiles.length ? (
              <>
                {{
                  Images: (
                    <ImageMedia
                      files={img}
                      selectMultiple={selectMultiple}
                      setVal={setLatestVal}
                      search={search}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  ),
                  Videos: (
                    <VideoMedia
                      files={vid}
                      selectMultiple={selectMultiple}
                      setVal={setLatestVal}
                      search={search}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  ),
                  PDFs: (
                    <PdfMedia
                      files={pdf}
                      selectMultiple={selectMultiple}
                      setVal={setLatestVal}
                      search={search}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  ),
                }[activeTab] || (
                  <AllMedia
                    files={allFiles}
                    selectMultiple={selectMultiple}
                    setVal={setLatestVal}
                    search={search}
                    setAllSelectedItems={setAllSelectedItems}
                  />
                )}

                {latestVal !== "" ? (
                  <FileInfo
                    currentChecked={latestVal}
                    reset={setLatestVal}
                    files={allFiles}
                    insertable={insertable}
                    selectedImage={selectedImage}
                    allSelectedItems={allSelectedItems}
                  />
                ) : (
                  <div className="rw_media_library_file_info">
                    <h4 className="rw_media_library_file_info_title text-center fst-italic">
                      No File Selected
                    </h4>
                  </div>
                )}
              </>
            ) : (
              <h2>No Results Found</h2>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Library.defaultProps = {
  selectedImage: null,
  tabViewsTitles: ["All Media", "Images", "Videos", "PDFs"],
  uploadMultiple: false,
  selectMultiple: false,
  isLoading: false,
  onUpload: null,
  insertable: true,
};
