import React from "react";
import { CreateService } from "./modules/CreateService";
import { ListServices } from "./modules/ListServices";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import Papa from "papaparse";
import { Helmet } from "../../layouts/Helmet";

export const Services = () => {
  function handleCsv(e) {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {},
      });
    }
  }
  return (
    <ScreenWrapper
      handleCsv={handleCsv}
      importIcon={true}
      screenTitle="Services"
    >
      <Helmet metaTitle="Services | Spark | RebelWare" />
      <CreateService />
      <ListServices />
    </ScreenWrapper>
  );
};
