import dompurify from "dompurify";
// import Loader from "react-loader-spinner";
import React, { useRef } from "react";
import { Overlay, Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getCaseStudies, deleteCaseStudy } from "../../../../api/caseStudies";
import PencilIcon from "../../../components/assets/PencilIcon";
import TrashIcon from "../../../components/assets/TrashIcon";
import { CollectionList } from "../../../layouts/CollectionList";

function ListCaseStudies() {
  let currentID = "";
  const ref = useRef(null);
  const sanitizer = dompurify.sanitize;
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    "getCaseStudies",
    getCaseStudies
  );

  const { mutate } = useMutation(
    ["deleteCaseStudy", currentID],
    () => deleteCaseStudy(currentID),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCaseStudies");
      },
    }
  );

  function handleDeleteSelect(data) {
    currentID = data;
    mutate();
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading case studies</h4>;
  } else {
    return (
      <CollectionList
        title="View / Edit Case Studies"
        loading={isLoading}
        error={isError}
      >
        {data.map((caseStudy) => (
          <div className="collection-list-card" key={caseStudy._id}>
            {/* {caseStudy.image ?
            <div className="case-study-list-image">
            </div>
            : null
          } */}
            <div className="collection-list-col collection-list-image">
              {caseStudy.image ? (
                caseStudy.image.map((img, idx) => (
                  <img
                    key={`img_${idx}`}
                    width={133}
                    height={200}
                    alt={img.fileAlt}
                    src={img.fileUrl[3]}
                  />
                ))
              ) : (
                <img
                  width={133}
                  height={200}
                  alt="team member placeholder"
                  src="https://via.placeholder.com/350x525"
                />
              )}
            </div>
            <div className="collection-list-col collection-list-info">
              <div className="mb-sm-4">
                <p>{caseStudy.title}</p>
                <p>{caseStudy.company}</p>
              </div>
              <div>
                <p>{caseStudy.url}</p>
              </div>
            </div>
            <div className="collection-list-col collection-list-textarea">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(caseStudy.description),
                }}
              ></div>
              {/* <div className="d-flex">
              <div className="me-4 d-flex">
                <p className="me-2 fw-bold">Departments:</p>
                {caseStudy.department.map((item, i) => (
                  <div className="me-2" key={`dept_${i}`}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <p className="me-2 fw-bold">Industries:</p>
                {caseStudy.industry.map((item, i) => (
                  <div className="me-2" key={`ind_${i}`}>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </div> */}
            </div>
            <div className="collection-list-buttons">
              <Link to={{ pathname: `/case-studies/edit/${caseStudy._id}` }}>
                <PencilIcon width="1rem" height="1rem" />
              </Link>
              <button
                className="transparent"
                onClick={() => handleDeleteSelect(caseStudy._id)}
              >
                <TrashIcon width="1rem" height="1rem" />
              </button>
            </div>
          </div>
        ))}
      </CollectionList>
    );
  }
}

export default ListCaseStudies;
