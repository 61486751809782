import React from "react";

export const PresentationLegend = () => {
  return (
    <div className="dashboard-legend">
      <div className="legend legend-draft">Draft</div>
      <div className="legend legend-never-expires">Live - Never Expires</div>
      <div className="legend legend-expires">Live - Set Expiration</div>
      <div className="legend legend-expired">Expired</div>
      <div className="legend legend-scheduled">Scheduled</div>
    </div>
  );
};
