import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { slugify } from "../../../components/slugify";
import { getPublicPresentation } from "../../../../api/presentations";
import { FrontEndLayout } from "./layouts/FrontEndLayout";
import { ClientInfo } from "./modules/ClientInfo";
import { frontendModules } from "./modules/components";
import { Helmet } from "../../../layouts/Helmet";

export const Presentations = (props) => {
  const path = window.location.pathname.split("/");
  const presentationID = path[path.length - 1];
  const { data, isLoading, isError } = useQuery(
    ["getPublicPresentation", presentationID],
    () => getPublicPresentation(presentationID)
  );

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (isError) {
    return <h4>There was an error loading this presentation</h4>;
  } else if (!data.length) {
    console.log(data);
    return <h4>No Presentation Found Here</h4>;
  } else {
    const presentationData = data[0];
    const presentationComponents = frontendModules.filter((o1) =>
      presentationData.layout.some((o2) => o1.id === o2)
    );

    const client = presentationData.data.client.clientName
      ? presentationData.data.client.clientName
      : "Spark";
    console.log(presentationData);
    return (
      <FrontEndLayout>
        <Helmet
          metaTitle={`${presentationData.presentationTitle} | ${client} | RebelWare`}
        />
        <div className="presentation-page">
          {presentationData.data.client.active && (
            <ClientInfo data={presentationData.data.client} />
          )}
          <Row>
            <Col md={3}>
              <div className="presentation-side-navigation">
                <p className="navigation-title">Jump to:</p>
                {presentationComponents.map((component, idx) => (
                  <div key={`presentation-nav-${idx}`}>
                    <a href={`#${slugify(component.name)}`}>{component.name}</a>
                  </div>
                ))}
              </div>
            </Col>
            <Col>
              <div className="presentation-display">
                {presentationComponents.map((component, idx) => (
                  <div
                    key={`presentation-${idx}`}
                    id={slugify(component.name)}
                    className="presentation-item-container"
                  >
                    <component.component
                      data={presentationData.data[component.id]}
                      title={component.name}
                      id={slugify(component.name)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </FrontEndLayout>
    );
  }
};
