import React from "react";
import dompurify from "dompurify";
import { Row, Col } from "react-bootstrap";
import { ComponentWrapper } from "../layouts/ComponentWrapper";

export const CaseStudies = ({ id, data, title }) => {
  const active = data.active;
  const teamMembers = data.teamMembers;
  const sanitizer = dompurify.sanitize;

  if (!active) {
    return null;
  } else {
    return (
      <ComponentWrapper id={id} title={title}>
        <Row>
          case studies
          {/* {teamMembers.map((teamMember, idx) => (
            <Col key={`tm-${idx}`} md={4}>
              <figure>
                <img
                  src={teamMember.image[0].fileUrl[3]}
                  width="100%"
                  height="auto"
                />
              </figure>
              <h3>{teamMember.name}</h3>
              <h4>{teamMember.title}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(teamMember.bio),
                }}
              ></div>
            </Col>
          ))} */}
        </Row>
      </ComponentWrapper>
    );
  }
};
