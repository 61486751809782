import React from "react";

function ClientImagePreviewModule() {
  return (
    <div className="p-3 bg-dark">
      <h4 className="text-white">Client Image</h4>
    </div>
  );
}

export default ClientImagePreviewModule;
