import React, { useEffect, useState } from "react";
import PencilIcon from "../assets/PencilIcon";
import TrashIcon from "../assets/TrashIcon";

export const ShowPdfs = ({ selectedDocument, showModal, documentSelect }) => {
  const [documents, setDocuments] = useState([]);
  let docs = selectedDocument;

  function handleRemove(id) {
    docs = docs.filter((item) => item._id !== id);
  }

  useEffect(() => {
    setDocuments(docs);
  }, [docs]);

  if (!documents) {
    return <p className="show-document-text my-3">No Documents Selected</p>;
  } else {
    return (
      <div className="show-document-container my-3">
        {documents.map((doc) => (
          <div className="show-document-item" key={`document_${doc._id}`}>
            <p>{doc.fileName}</p>
            <div className="show-document-buttons">
              <button className="transparent" onClick={showModal}>
                <PencilIcon withd={14} height={14} />
              </button>
              <button
                className="transparent"
                onClick={() => handleRemove(doc._id)}
              >
                <TrashIcon withd={14} height={14} />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }
};
