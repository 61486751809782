import React, { useState, useEffect } from "react";

export const PdfMedia = ({
  files,
  setVal,
  selectMultiple,
  setAllSelectedItems,
}) => {
  const [checkedArr, setCheckedArr] = useState([]);

  function handleChecked(e) {
    // set id's to an array
    if (e.currentTarget.checked) {
      setCheckedArr([...checkedArr, e.target.value]);
    } else {
      const newArr = checkedArr.filter((item) => item !== e.target.value);
      setCheckedArr(newArr);
    }
  }

  // rerender every time a file is checked
  useEffect(() => {
    if (checkedArr.length) {
      // set the last item of the array of files
      setVal(checkedArr[checkedArr.length - 1]);
      // set all tiems
      setAllSelectedItems(checkedArr);
    } else {
      setVal("");
      setAllSelectedItems([]);
    }
  }, [checkedArr]);

  if (!files.length) {
    return <h2>No Files Found</h2>;
  } else {
    return (
      <div className="rw_media_list">
        {files.map((file) => (
          <div key={file._id} className="rw_media_list_item">
            <input
              type={selectMultiple ? "checkbox" : "radio"}
              id={file._id}
              name="media"
              value={file._id}
              onChange={(e) => {
                handleChecked(e);
              }}
            />
            <label htmlFor={file._id}>
              <img src={file.image} alt={file.fileName} />
            </label>
            {/* <label htmlFor={file._id} style={{ backgroundImage: `url(${file.image})` }}>
            <img src={`${file.image}`} width="auto" height="auto" alt={file.fileName} />
            <AddLabel fileName={file.fileName} fileType={file.fileType} />
          </label> */}
          </div>
        ))}
      </div>
    );
  }
};
