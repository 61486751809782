import React from "react";
import MainNavigation from "../navigation/MainNavigation";
import MobileNavigation from "../navigation/MobileNavigation";

function Sidepanel() {
  return (
    <div className="sidebar-wrapper">
      <nav className="sidebar-container">
        <MobileNavigation />
        <MainNavigation />
      </nav>
    </div>
  );
}

export default Sidepanel;
