import React from "react";
import { Button } from "react-bootstrap";

export const PublishBar = ({
  setAddModuleShow,
  setSaveTemplateShow,
  savePresentation,
}) => {
  function SaveTemplate() {
    if (setSaveTemplateShow) {
      return (
        <Button variant="dark" onClick={() => setSaveTemplateShow(true)}>
          Save As Template
        </Button>
      );
    } else {
      return null;
    }
  }

  function SavePresentation() {
    if (savePresentation) {
      return (
        <Button variant="tertiary" onClick={() => savePresentation()}>
          Publish
        </Button>
      );
    } else {
      return null;
    }
  }

  function AddModule() {
    if (setAddModuleShow) {
      return (
        <Button variant="primary" onClick={() => setAddModuleShow(true)}>
          Add Module
        </Button>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="publish-bar">
      <SaveTemplate />
      <SavePresentation />
      <AddModule />
    </div>
  );
};
PublishBar.defaultProps = {
  setAddModuleShow: null,
  setSaveTemplateShow: null,
  savePresentation: null,
};
