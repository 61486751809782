import React from "react";
import dompurify from "dompurify";
import { Col, Figure, Row } from "react-bootstrap";

export const ClientInfo = ({ data }) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div className="frontend-client-information">
      <Row className="align-items-center">
        {data.clientImage && (
          <Col md={3} className="client-information-image">
            <Figure>
              <Figure.Image
                width="100%"
                height="auto"
                src={data.clientImage[0].fileUrl[2]}
                alt={data.clientImage[0].fileAlt}
              ></Figure.Image>
            </Figure>
          </Col>
        )}
        <Col
          className={`client-information-details ${
            data.clientImage ? "details-border" : ""
          }`}
        >
          <div>
            <h1>{data.clientName}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(data.clientNote),
              }}
            ></div>
            <div className="signature">
              <p>Bryn Tindall</p>
              <p>CEO & Owner </p>
              <p>M: 860-930-1105</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
