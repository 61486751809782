import React from "react";
import { Container } from "react-bootstrap";
import { Presentations } from "../Presentations";
import { FrontEndHeader } from "./FrontEndHeader";

export const FrontEndLayout = ({ children }) => {
  return (
    <div id="presentation-content-wrapper">
      <FrontEndHeader />
      <main id="presentation-container" className="w-100">
        <Container>{children}</Container>
      </main>
    </div>
  );
};
