import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import { getCaseStudy, editCaseStudy } from "../../../api/caseStudies";
// import DepartmentSelect from "../../components/DepartmentSelect";
// import IndustrySelect from "../../components/IndustrySelect";
import { AddMediaComponent } from "../../components/media/AddMediaComponent";
import { CollectionCreate } from "../../layouts/CollectionCreate";
import { ScreenWrapper } from "../../layouts/ScreenWrapper";
import { Helmet } from "../../layouts/Helmet";

function EditCaseStudy() {
  // const [department, setDepartment] = useState([])
  // const [industry, setIndustry] = useState([])
  // const [currentDepartment, setCurrentDepartment] = useState([])
  // const [currentIndustry, setCurrentIndustry] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/");
  const caseStudyID = path[path.length - 1];
  const { user } = useAuth0();

  const { data } = useQuery(["getCaseStudie", caseStudyID], () =>
    getCaseStudy(caseStudyID)
  );

  const { mutate } = useMutation(editCaseStudy, {
    onSuccess: (data) => {
      queryClient.setQueryData(["getCaseStudies", { id: caseStudyID }], data);
      navigate("/case-studies");
    },
  });

  // set data for inputs
  const [inputValue, setInputValue] = useState({
    company: "",
    title: "",
    url: "",
  });

  // set values on load
  useEffect(() => {
    if (data) {
      setSelectedImage(data[0].image);
      setOriginalTitle(data[0].title);
      setInputValue({
        company: data[0].company,
        title: data[0].title,
        url: data[0].url,
      });
      if (data[0].description) {
        setDescription(data[0].description);
      }
      // if (data[0].department) {
      //   let dept = []
      //   data[0].department.map(p => (
      //     dept.push({ value: p, label: p })
      //   ))
      //   setCurrentDepartment(dept)
      //   console.log("ecs 63", currentDepartment)
      // }
      // if (data[0].industry) {
      //   let ind = []
      //   data[0].industry.map(p => (
      //     ind.push({ value: p, label: p })
      //   ))
      //   setCurrentIndustry(ind)
      // }
    }
  }, [data]);

  // update inputs
  function handleInput(e) {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // console.log(department)
    // let newDeptArr = []
    // if (department) {
    //   department.map(dept => {
    //     newDeptArr.push(dept.value)
    //   })
    // }
    // console.log(newDeptArr)
    // let newIndArr = []
    // if (industry) {
    //   industry.map(ind => {
    //     newIndArr.push(ind.value)
    //   })
    // }
    mutate({
      id: caseStudyID,
      title: inputValue.title,
      image: selectedImage,
      company: inputValue.company,
      url: inputValue.url,
      // department: newDeptArr,
      // industry: newIndArr,
      description: description,
      user: user.sub,
    });
  }

  if (!data) {
    return <>Fetching</>;
  }
  return (
    <ScreenWrapper importIcon={false} screenTitle={`Edit ${originalTitle}`}>
      <Helmet metaTitle="Edit Case Study | Spark | RebelWare" />

      <CollectionCreate>
        <AddMediaComponent
          imageSelect={setSelectedImage}
          selectedImage={selectedImage}
          message="Recommended Image size is 350px x 525px"
        />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Title"
                placeholder="Enter Title"
                value={inputValue.title}
                onChange={handleInput}
                name="title"
              />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Company"
                placeholder="Enter Company Name"
                value={inputValue.company}
                onChange={handleInput}
                name="company"
              />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Control
                type="url"
                className="form-control"
                aria-label="url"
                placeholder="Enter URL"
                value={inputValue.url}
                onChange={handleInput}
                name="url"
              />
            </Col>
          </Row>
          {/* <Row>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <DepartmentSelect currentState={currentDepartment} selectedValues={(selectedOption) => setDepartment(selectedOption)} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="multi-select-container">
                <IndustrySelect currentState={currentIndustry} selectedValues={(selectedOption) => setIndustry(selectedOption)} />
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} className="mb-3">
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
              />
            </Col>
          </Row>
          <div className="mt-5 d-flex justify-content-between">
            <Button
              as={Link}
              to="/case-studies"
              variant="secondary"
              size="lg"
              type="button"
            >
              Cancel
            </Button>
            <Button variant="primary" size="lg" type="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </CollectionCreate>
    </ScreenWrapper>
  );
}

export default EditCaseStudy;
