import React, { useState, useEffect } from "react";
import SearchIcon from "../assets/SearchIcon";

export const Search = ({ activeTab, onSubmit }) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal("");
    onSubmit("");
  }, [activeTab]);

  function handleInputChange(e) {
    setVal(e.target.value);
  }

  function handleSearch() {
    onSubmit(val);
  }

  return (
    <div className="rw_media_library_search_container">
      <h2 className="rw_media_library_search_title">{activeTab}</h2>
      <div className="rw_media_library_search_wrapper">
        <input
          className="rw_media_library_search_input"
          type="text"
          placeholder="Search Media"
          value={val}
          onChange={handleInputChange}
          name="search"
        />
        <button
          className="rw_media_library_search_button"
          onClick={handleSearch}
        >
          <SearchIcon />
        </button>
      </div>
    </div>
  );
};
