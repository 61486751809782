import { CaseStudiesModule } from "./editor/CaseStudiesModule";
import { ContactInformationModule } from "./editor/ContactInformationModule";
import { DocumentsModule } from "./editor/DocumentsModule";
import { ImageModule } from "./editor/ImageModule";
import { MeetTheTeamModule } from "./editor/MeetTheTeamModule";
import { ServicesModule } from "./editor/ServicesModule";
import { TestimonialsModule } from "./editor/TestimonialsModule";
import { TextModule } from "./editor/TextModule";
import { VideoModule } from "./editor/VideoModule";
import ClientImagePreviewModule from "./preview/ClientImagePreviewModule";
import ClientNamePreviewModule from "./preview/ClientNamePreviewModule";
import ClientNotePreviewModule from "./preview/ClientNotePreviewModule";
import ContactInformationPreviewModule from "./preview/ContactInformationPreviewModule";
import MeetTheTeamPreviewModule from "./preview/MeetTheTeamPreviewModule";

export const addableModules = [
  // {
  //   id: "2",
  //   name: "Client Information",
  //   component: ClientInfoModule,
  // },
  {
    id: "3",
    name: "Meet The Team",
    component: MeetTheTeamModule,
  },
  {
    id: "4",
    name: "Contact Information",
    component: ContactInformationModule,
  },
  {
    id: "5",
    name: "Documents",
    component: DocumentsModule,
  },
  {
    id: "6",
    name: "Case Studies",
    component: CaseStudiesModule,
  },
  {
    id: "7",
    name: "Video",
    component: VideoModule,
  },
  {
    id: "8",
    name: "Testimonials",
    component: TestimonialsModule,
  },
  {
    id: "9",
    name: "Services",
    component: ServicesModule,
  },
  {
    id: "10",
    name: "Images",
    component: ImageModule,
  },
  {
    id: "11",
    name: "Text",
    component: TextModule,
  },
];

export const previewModules = [
  {
    id: "2",
    name: "Client Name Preview",
    component: ClientNamePreviewModule,
  },
  {
    id: "3",
    name: "Client Image",
    component: ClientImagePreviewModule,
  },
  {
    id: "4",
    name: "Note To Client",
    component: ClientNotePreviewModule,
  },
  {
    id: "5",
    name: "Meet The Team",
    component: MeetTheTeamPreviewModule,
  },
  {
    id: "6",
    name: "Contact Information",
    component: ContactInformationPreviewModule,
  },
];
