import moment from "moment";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "../presentationInformation/publishStatus/PublishDatePicker";

function DatePickerModal({ setdate, date, onHide, show }) {
  const [dateTime, setDateTime] = useState("");

  const handleChange = (val) => setDateTime(String(val));

  // const yesterday = moment().subtract(1, "day");
  // const valid = function (current) {
  //   return current.isAfter(yesterday);
  // };

  function saveDate(e) {
    setdate(dateTime);
    onHide();
  }

  function cancelDate() {
    if (date !== "") {
      setdate(date);
    } else {
      setdate("");
    }
    onHide();
  }
  return (
    <Modal show={show} size="md" aria-labelledby="add-modules" centered>
      <Modal.Body>
        <h4 className="text-center py-3">{`${
          dateTime === ""
            ? "Select Date and Time"
            : moment(dateTime).format("MMM Do YYYY h:mm a")
        }`}</h4>
        <Datetime
          // isValidDate={valid}
          onChange={handleChange}
          closeOnClickOutside={false}
          open={true}
          input={false}
          timeConstraints={{
            minutes: { step: 15 },
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveDate}>Save</Button>
        <Button onClick={cancelDate}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DatePickerModal;
